import './PayExample.css';
import { motion } from 'framer-motion'

function PayExample({ file }) {

    return (
        <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1 }}
         className='pay-example'>
            <div className='pay-example__card'>
                <div className='pay-example__header'>
                    <div className='pay-example__header__line'></div>
                    <div className='pay-example__header__line'></div>
                    <div className='pay-example__header__line pay-example__header__line_orange'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                            <path d="M11.0833 3.50016H9.91667C9.91667 1.89016 8.61 0.583496 7 0.583496C5.39 0.583496 4.08333 1.89016 4.08333 3.50016H2.91667C2.275 3.50016 1.75 4.02516 1.75 4.66683V11.6668C1.75 12.3085 2.275 12.8335 2.91667 12.8335H11.0833C11.725 12.8335 12.25 12.3085 12.25 11.6668V4.66683C12.25 4.02516 11.725 3.50016 11.0833 3.50016ZM7 1.75016C7.96833 1.75016 8.75 2.53183 8.75 3.50016H5.25C5.25 2.53183 6.03167 1.75016 7 1.75016ZM11.0833 11.6668H2.91667V4.66683H11.0833V11.6668ZM7 7.00016C6.03167 7.00016 5.25 6.2185 5.25 5.25016H4.08333C4.08333 6.86016 5.39 8.16683 7 8.16683C8.61 8.16683 9.91667 6.86016 9.91667 5.25016H8.75C8.75 6.2185 7.96833 7.00016 7 7.00016Z" fill="white" />
                        </svg>
                    </div>
                </div>
                <div className='pay-example__card__box-info'>
                    <div className='pay-example__card__gallery'>
                        <img alt='' src={file} className='pay-example__card__img'></img>
                        <img alt='' src={file} className='pay-example__card__img_mini'></img>
                    </div>
                    <div className='pay-example__card__info'>
                        <div className='pay-example__header__line pay-example__card__info__line-1'></div>
                        <div className='pay-example__header__line pay-example__card__info__line-2'></div>
                        <p className='pay-example__card__info__price'>$ 65</p>
                        <div className='pay-example__card__info__button-block'>
                            <button className='pay-example__card__info__button'>
                                <p>-</p>
                                <p>1</p>
                                <p>+</p>
                            </button>
                            <button className='pay-example__card__info__button pay-example__card__info__button_black'>Add to card</button>
                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                                <g clip-path="url(#clip0_104_2301)">
                                    <path d="M3.30069 0.00605392C3.2753 0.00807285 3.19304 0.0171556 3.11788 0.0262394C1.60968 0.199831 0.37163 1.33322 0.0699892 2.81581C-0.0650889 3.48192 0.00194235 4.33675 0.243661 5.05938C0.499599 5.81934 0.929208 6.48343 1.66249 7.25147C2.00983 7.6148 2.3064 7.8873 3.2753 8.73507C3.50991 8.93995 3.77093 9.16905 3.85421 9.24474C4.89421 10.1733 5.61632 10.9524 6.23686 11.8153L6.3689 12H6.50296L6.63702 11.999L6.76905 11.8052C7.33476 10.9756 7.99999 10.2308 8.98819 9.32145C9.18218 9.1418 9.40257 8.945 10.0312 8.38991C11.0022 7.53003 11.4988 7.03448 11.901 6.52279C12.5541 5.69016 12.8841 4.90496 12.9816 3.94617C13.0009 3.74836 12.9989 3.27502 12.9766 3.10345C12.8699 2.291 12.5287 1.5926 11.963 1.03045C11.3973 0.468291 10.6934 0.128174 9.87687 0.0232115C9.68085 -0.00101089 9.25429 -0.00101089 9.05421 0.0232115C8.30976 0.117072 7.65366 0.413792 7.10421 0.905297C6.91022 1.07889 6.69186 1.32616 6.56085 1.52094C6.53038 1.56636 6.50296 1.6037 6.49991 1.6037C6.49686 1.6037 6.46944 1.56636 6.43897 1.52094C6.30796 1.32616 6.0896 1.07889 5.89561 0.905297C5.35632 0.422875 4.70733 0.125146 3.98827 0.0302763C3.83999 0.0100918 3.41749 -0.00403786 3.30069 0.00605392Z" fill="black" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_104_2301">
                                        <rect width="13" height="12" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                        <div className='pay-example__card__info_bottom'>
                            <div className='pay-example__header__line pay-example__card__info_bottom__line-1'></div>
                            <div className='pay-example__header__line pay-example__card__info_bottom__line-2'></div>
                            <div className='pay-example__header__line pay-example__card__info_bottom__line-3'></div>
                            <div className='pay-example__header__line pay-example__card__info_bottom__line-4'></div>
                        </div>
                    </div>

                </div>
            </div>
            <p className='pay-example__text'>Your products are so easy to buy now that you can accept online payments worldwide</p>
        </motion.div>
    );
}

export default PayExample