import { useState } from "react";
import validator from "validator";
import "./SubscribeForm.css";

function SubscribeForm() {
  const [value, setValue] = useState("");
  const [validity, setValidity] = useState({
    errorMessage: "",
    validState: false,
  });

  function handleChange(e) {
    const value = e.target.value;
    setValue(value);

    if (!value) {
      setValidity({
        errorMessage: "",
        validState: false,
      });
    }
    if (value.length >= 2) {
      if (validator.isEmail(value)) {
        setValidity({
          errorMessage: "",
          validState: true,
        });
      } else {
        setValidity({
          errorMessage: !e.target.validity.valid
            ? e.target.validationMessage
            : "Invalid email",
          validState: false,
        });
      }
    }
  }

  function handleSubmit(evt) {
    evt.preventDefault();
    setValue("");
    setValidity({
      errorMessage: "",
      validState: false,
    });
  }

  return (
    <div className="subscribe">
      <p className="subscribe__title">
        Want to stay updated with the latest e&#8209;commerce&nbsp;news?
        Subscribe to our newsletter!
      </p>
      <form
        className={`subscribe__form ${
          Boolean(value) ? "subscribe__form_focus" : ""
        }`}
        onSubmit={handleSubmit}
      >
        <input
          className={`subscribe__input ${
            Boolean(value) ? "subscribe__input_focus" : ""
          }`}
          name="email"
          type="email"
          inputMode="email"
          value={value || ""}
          placeholder="Email address"
          onChange={handleChange}
        />
        <button
          className={`subscribe__submit-btn ${
            !validity.validState ? "subscribe__submit-btn_disabled" : ""
          }`}
          type="submit"
          disabled={!validity.validState}
        >
          Subscribe
        </button>
      </form>
    </div>
  );
}

export default SubscribeForm;
