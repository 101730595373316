import commission from "../../../assets/images/partners/pros/commission-icon.webp";
import support from "../../../assets/images/partners/pros/support-icon.webp";
import marketing from "../../../assets/images/partners/pros/marketing-icon.webp";
import opportunity from "../../../assets/images/partners/pros/opportunity-icon.webp";
import "./PartnersPros.css";

const cards = [
  {
    text: "Commission for every new client",
    icon: commission,
  },
  {
    text: "Priority guidance and tech support",
    icon: support,
  },
  {
    text: "Special marketing campaigns",
    icon: marketing,
  },
  {
    text: "Opportunity to grow any business",
    icon: opportunity,
  },
];

function PartnersPros() {
  return (
    <div className="partners-pros">
      <p className="partners-pros__title">Become Sellavi's business partner</p>
      <ul className="partners-pros__list">
        {cards.map((item, i) => (
          <li className="partners-pros__item" key={`card-${i}`}>
            <p className="partners-pros__text">{item.text}</p>
            <img className="partners-pros__icon" src={item.icon} alt="" />
          </li>
        ))}
      </ul>
    </div>
  );
}

export default PartnersPros;
