import './Global.css';
import stars from '../../../assets/images/aboutUs/stars.png'
import blur from '../../../assets/images/aboutUs/blur.png'
import global from '../../../assets/images/aboutUs/global.png'
import spy from '../../../assets/images/aboutUs/spy.png'
import { motion } from 'framer-motion'

function Global() {
    return (
        <div className='global'>
            <p className='global__title'>Those who started selling with us locally are now successful globally</p>
            <div className='global__subtitle-box'>
                <div className='global__subtitle global__subtitle_first'>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45" fill="none">
                            <path d="M22.4998 39.3751C31.8196 39.3751 39.3748 31.8199 39.3748 22.5001C39.3748 13.1803 31.8196 5.62509 22.4998 5.62509C13.18 5.62509 5.62476 13.1803 5.62476 22.5001C5.62476 31.8199 13.18 39.3751 22.4998 39.3751Z" fill="#2BBC5D" stroke="#2BBC5D" strokeWidth="1.4734" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M23.5729 26.0136H26.5053V12.8571H23.5729V26.0136ZM18.5175 26.0136H21.4499V12.8571H18.5175V26.0136ZM14.4642 27.7207L15.6091 27.1517C16.0622 26.9327 16.3716 26.8017 16.6101 26.8017C16.9914 26.8017 17.206 27.0646 17.5164 27.4147C18.3269 28.3117 19.7821 29.6697 22.5 29.6697C25.2178 29.6697 26.673 28.3127 27.4835 27.4147C27.7939 27.0646 28.0085 26.8017 28.3897 26.8017C28.6283 26.8017 28.9377 26.9327 29.3908 27.1517L30.5357 27.7207C28.5325 31.0918 25.5282 32.1429 22.5 32.1429C19.4717 32.1429 16.4674 31.0918 14.4642 27.7207Z" fill="white" />
                        </svg>
                    </div>
                    Sellavi works across the globe
                </div>

                <div className='global__subtitle global__subtitle_second'>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45" fill="none">
                            <path d="M22.4998 39.3751C31.8196 39.3751 39.3748 31.8199 39.3748 22.5001C39.3748 13.1803 31.8196 5.62509 22.4998 5.62509C13.18 5.62509 5.62476 13.1803 5.62476 22.5001C5.62476 31.8199 13.18 39.3751 22.4998 39.3751Z" fill="#2BBC5D" stroke="#2BBC5D" strokeWidth="1.4734" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M23.5729 26.0136H26.5053V12.8571H23.5729V26.0136ZM18.5175 26.0136H21.4499V12.8571H18.5175V26.0136ZM14.4642 27.7207L15.6091 27.1517C16.0622 26.9327 16.3716 26.8017 16.6101 26.8017C16.9914 26.8017 17.206 27.0646 17.5164 27.4147C18.3269 28.3117 19.7821 29.6697 22.5 29.6697C25.2178 29.6697 26.673 28.3127 27.4835 27.4147C27.7939 27.0646 28.0085 26.8017 28.3897 26.8017C28.6283 26.8017 28.9377 26.9327 29.3908 27.1517L30.5357 27.7207C28.5325 31.0918 25.5282 32.1429 22.5 32.1429C19.4717 32.1429 16.4674 31.0918 14.4642 27.7207Z" fill="white" />
                        </svg>
                    </div>
                    With us, you can expand your presence and reach a wider audience day by day
                </div>
            </div>
            <div className='global__glob-box'>
                <motion.img
                    initial={{ y: '10%', scale: 0.5 }}
                    whileInView={{ y: '0%', scale: 1 }}
                    transition={{ duration: 3 }}
                    alt='' src={global} className='global__glob'>
                </motion.img>
                <motion.img
                    initial={{ y: '20%', scale: 0.5 }}
                    whileInView={{ y: '0%', scale: 1 }}
                    transition={{ duration: 3 }}
                    onAnimationComplete={() => {
                        // Start an infinite rotation animation after the scaling is complete
                        document.querySelector('.global__spy').style.animation = 'rotate 10s linear infinite';
                    }}
                    alt='' src={spy} className='global__spy'>
                </motion.img>
            </div>
            <img alt='' src={stars} className='global__stars'></img>
            <img alt='' src={blur} className='global__blur'></img>

        </div>
    );
}

export default Global