import useWindowSize from '../../../assets/hooks/useWindowSize';
import {
  BlurLaptop,
  BlurMobile,
  ButtonSmileLaptop,
  ButtonSmileMobile,
} from '../../../assets/icons/icons';
import {
  FOOTER_CONTACTS,
  FOOTER_LOGO_COLOR,
  FOOTER_NAV_LINKS,
} from '../../../assets/utils/constants';
import './Footer.css';
import { Link } from 'react-router-dom';

function Footer({ onSignupOpen }) {
  const { width } = useWindowSize();

  return (
    <div className='footer'>
      <div className='footer__box'>
        <p className='footer__title footer__title_type_green'>
          Enjoy a free 14-day trial!
        </p>
        <p className='footer__title'>No credit card required</p>
        <p className='footer__subtitle'>
          Launch your online store and kickstart sales&nbsp;today!
        </p>
        <button className='footer__button' type='button' onClick={onSignupOpen}>
          START NOW
          <div className='button__smile_laptop'>
            <ButtonSmileLaptop />
          </div>
          <div className='button__smile_mobile'>
            <ButtonSmileMobile />
          </div>
        </button>

        <div className='footer__content'>
          <div className='footer__links-block'>
            <ul className='footer__nav-links-list'>
              {FOOTER_NAV_LINKS.map((item, i) => (
                <li
                  className='footer__nav-link-item'
                  key={`footer-nav-link-${i}`}
                >
                  <Link className='footer__nav-link' to={item.path}>
                    {width <= 580 && item.mobileTitle
                      ? item.mobileTitle
                      : item.title}
                  </Link>
                </li>
              ))}
              <li
                className='footer__nav-link-item'
              >
                <button className='footer__nav-link' type='button' onClick={onSignupOpen}>
                  Free trial
                </button>
              </li>
            </ul>

            <ul className='footer__contacts-list'>
              {FOOTER_CONTACTS.map((item, i) => (
                <li
                  className='footer__contact-item'
                  key={`footer-contact-${i}`}
                >
                  <a className='footer__contact-link' href={item.link}>
                    {item.title}
                  </a>
                </li>

              ))}
            </ul>
          </div>

          <ul className='footer__logos-list'>
            {FOOTER_LOGO_COLOR.map((item, i) => (
              <li
                className='footer__logo-item'
                key={`footer-logo-${item.name}`}
              >
                <img
                  className={`footer__logo footer__logo_type_${item.name}`}
                  src={item.logo}
                  alt={item.name}
                />
              </li>
            ))}
          </ul>
        </div>
        <p className='footer__rights'>
          All rights reserved 2024 | ©&nbsp;Sellavi
        </p>
      </div>

      <div className='button__blur button__blur_laptop'>
        <BlurLaptop />
      </div>
      <div className='button__blur button__blur_mobile'>
        <BlurMobile />
      </div>
    </div>
  );
}

export default Footer;
