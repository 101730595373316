import "./QuoteElement.css";
import exclamation from "../../../../assets/images/article/quote-icon.png";
import { renderArticleChildren } from "../../../../assets/utils/utils";

function QuoteElement({ data }) {
  return (
    <div className="quote-element">
      <img className="quote-element__icon" src={exclamation} alt="" />
      <p className="quote-element__text">{renderArticleChildren(data)}</p>
    </div>
  );
}

export default QuoteElement;
