import React, { useContext, useEffect, useRef } from 'react';
import { useInView } from 'framer-motion';

import video_white from '../../../assets/videos/marketing/grey.mp4';
import video_black from '../../../assets/videos/marketing/grey-black.mp4';
import lk from '../../../assets/images/marketing/lk.webp';

import './Marketing.css';
import { THEME_LIGHT_TYPE } from '../../../assets/utils/constants';
import { ThemeContext } from '../../../assets/contexts/themeContext';

function Marketing() {
    const videoRef = useRef(null);
    const isInView = useInView(videoRef, { once: true, margin: '-100px' });
    const { theme } = useContext(ThemeContext);
    useEffect(() => {
        const video = videoRef.current;
        if (!video) return;

        let isUnlocked = false; // Flag to indicate if video has been unlocked

        const unlockVideoAutoplay = () => {
            if (isUnlocked) return; // If already unlocked, do nothing
            isUnlocked = true; // Set the flag to true

            video.play().then(() => {
                video.pause();
            }).catch((error) => {
                console.error("Error trying to unlock video autoplay:", error);
            });

            // Clean up event listeners
            document.removeEventListener('touchstart', unlockVideoAutoplay);
            document.removeEventListener('click', unlockVideoAutoplay);
        };

        // Add event listeners to unlock video autoplay on the first user interaction
        document.addEventListener('touchstart', unlockVideoAutoplay);
        document.addEventListener('click', unlockVideoAutoplay);

        return () => {
            document.removeEventListener('touchstart', unlockVideoAutoplay);
            document.removeEventListener('click', unlockVideoAutoplay);
        };
    }, []);

    useEffect(() => {
        const video = videoRef.current;
        if (!video || !isInView) return;

        // Autoplay when in view
        video.play().catch((error) => {
            console.error("Error trying to play the video:", error);
        });
    }, [isInView]);

    const handleVideoError = (e) => {
        console.error("Video error event:", e);
        console.error("Native event details:", e.nativeEvent);
    };
    return (
        <div className='marketing'>
            <div className='marketing__content'>
                <h3 className='marketing__title'>Sell to everyone, <br />everywhere</h3>
                <p className='marketing__subtitle'>Diverse marketing channels to increase your sales and audience reach</p>
                <div className='marketing__box'>
                    {theme === THEME_LIGHT_TYPE ?
                        <video
                            key={`marketing__video_video_white`}
                            className='marketing__video'
                            ref={videoRef}
                            src={video_white}
                            autoPlay
                            muted
                            playsInline
                            loop
                            preload="metadata"
                            onError={handleVideoError}
                        >
                            Your browser does not support the video tag.
                        </video>
                        :
                        <video
                            key={`marketing__video_video_black`}
                            className='marketing__video'
                            ref={videoRef}
                            src={video_black}
                            autoPlay
                            muted
                            playsInline
                            loop
                            preload="metadata"
                            onError={handleVideoError}
                        >
                            Your browser does not support the video tag.
                        </video>
                    }

                    <img className='marketing__img' src={lk} alt='' />
                </div>
            </div>
        </div>
    );
}

export default Marketing;
