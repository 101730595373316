import './InstaExample.css';
import { FB } from '../../../../assets/icons/icons';
import circle from '../../../../assets/images/createWiithAI/instaCircle.png'
import { motion } from 'framer-motion'

function InstaExample({ file }) {

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.8 }}
            className='insta-example'>
            <div className='insta-example__card'>
                <div className='insta-example__card__user-info-block'>
                    <FB />
                    <div className=''>
                        <p className='insta-example__card__user-info-block__name'>Username</p>
                        <p className='insta-example__card__user-info-block__status'>Advertisement</p>
                    </div>
                </div>
                <img src={file} alt='' className='insta-example__photo-card__img'></img>
                <div className='insta-example__card__logo_shop'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                        <circle opacity="0.5" cx="9.5" cy="9.5" r="9.5" fill="#211B2A" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M7.88918 6.86111C7.88918 5.97132 8.6105 5.25 9.50029 5.25C10.3901 5.25 11.1114 5.97132 11.1114 6.86111H12.1114C12.1114 5.41903 10.9424 4.25 9.50029 4.25C8.05821 4.25 6.88918 5.41903 6.88918 6.86111H7.88918ZM6.27808 6.86121C5.72579 6.86121 5.27808 7.30893 5.27808 7.86121V12.2501C5.27808 13.3547 6.17351 14.2501 7.27808 14.2501H11.7225C12.8271 14.2501 13.7225 13.3547 13.7225 12.2501V7.86121C13.7225 7.30893 13.2748 6.86121 12.7225 6.86121H6.27808ZM7.38931 8.97216C7.68079 8.97216 7.91709 8.73586 7.91709 8.44438C7.91709 8.1529 7.68079 7.9166 7.38931 7.9166C7.09783 7.9166 6.86153 8.1529 6.86153 8.44438C6.86153 8.73586 7.09783 8.97216 7.38931 8.97216ZM12.1392 8.44438C12.1392 8.73586 11.9029 8.97216 11.6114 8.97216C11.3199 8.97216 11.0837 8.73586 11.0837 8.44438C11.0837 8.1529 11.3199 7.9166 11.6114 7.9166C11.9029 7.9166 12.1392 8.1529 12.1392 8.44438Z" fill="white" />
                    </svg>
                </div>
                <div className='insta-example__card__logo_price'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="62" height="26" viewBox="0 0 62 26" fill="none">
                        <path opacity="0.5" fillRule="evenodd" clipRule="evenodd" d="M31.6254 0.379899C31.1868 -0.126633 30.4012 -0.126633 29.9626 0.379899L27.302 3.45211C26.7977 4.03437 26.0654 4.36892 25.2952 4.36892H4.39916C1.96957 4.36892 0 6.33853 0 8.76808V20.8658C0 23.2953 1.96957 25.2649 4.39916 25.2649H56.6392C59.0688 25.2649 61.0383 23.2953 61.0383 20.8658V8.76808C61.0383 6.33853 59.0688 4.36892 56.6392 4.36892H36.2931C35.5226 4.36892 34.7902 4.03431 34.2859 3.45193L31.6254 0.379899Z" fill="#211B2A" />
                        <path d="M8.7771 17.3099C9.03505 17.2773 9.22279 17.2185 9.34034 17.1336C9.54604 16.9834 9.64889 16.7238 9.64889 16.3549C9.64889 16.0741 9.55257 15.852 9.35993 15.6888C9.24565 15.5941 9.05137 15.4961 8.7771 15.3949V17.3099ZM8.31182 12.1918C8.02775 12.1984 7.81715 12.2751 7.68002 12.422C7.54288 12.5657 7.47431 12.7632 7.47431 13.0146C7.47431 13.2889 7.57716 13.506 7.78287 13.666C7.89715 13.7542 8.07346 13.8358 8.31182 13.9109V12.1918ZM8.7869 19.3865H8.31182V18.4119C7.66859 18.3401 7.18861 18.1997 6.8719 17.9907C6.31356 17.6152 6.03929 16.9752 6.04908 16.0708H7.37146C7.41717 16.4822 7.48084 16.7581 7.56247 16.8985C7.68981 17.1173 7.93959 17.2593 8.31182 17.3246V15.2333L7.91511 15.1157C7.29146 14.9329 6.85067 14.6782 6.59273 14.3517C6.33805 14.0252 6.21071 13.6317 6.21071 13.1714C6.21071 12.8677 6.25968 12.5918 6.35764 12.3437C6.45886 12.0955 6.59926 11.8816 6.77884 11.7021C7.01066 11.4702 7.26861 11.3102 7.55268 11.2221C7.72573 11.1666 7.97878 11.1258 8.31182 11.0996V10.4482H8.7771V11.1094C9.30932 11.1519 9.72725 11.2825 10.0309 11.5012C10.5827 11.8506 10.8684 12.4171 10.888 13.2007H9.59991C9.57379 12.9102 9.52482 12.6979 9.45298 12.564C9.32891 12.3322 9.10361 12.2049 8.7771 12.182V14.0481C9.5542 14.3158 10.0766 14.5525 10.3444 14.7582C10.7852 15.1011 11.0056 15.5843 11.0056 16.2079C11.0056 17.0308 10.7035 17.6283 10.0995 18.0005C9.73052 18.2291 9.29299 18.3629 8.7869 18.4021V19.3865Z" fill="white" />
                        <path d="M14.8307 13.3281V12.3779C15.2715 12.3583 15.58 12.329 15.7563 12.2898C16.0371 12.2277 16.2657 12.1037 16.442 11.9176C16.5628 11.7902 16.6542 11.6204 16.7163 11.4082C16.7522 11.2809 16.7701 11.1862 16.7701 11.1241H17.9309V18.2258H16.5008V13.3281H14.8307Z" fill="white" />
                        <path d="M21.4572 14.7435C21.4572 15.5598 21.5242 16.1851 21.6581 16.6193C21.7919 17.0503 22.0662 17.2658 22.4809 17.2658C22.8955 17.2658 23.1665 17.0503 23.2939 16.6193C23.4245 16.1851 23.4898 15.5598 23.4898 14.7435C23.4898 13.8881 23.4245 13.2546 23.2939 12.8432C23.1665 12.4318 22.8955 12.2261 22.4809 12.2261C22.0662 12.2261 21.7919 12.4318 21.6581 12.8432C21.5242 13.2546 21.4572 13.8881 21.4572 14.7435ZM22.4809 11.0751C23.3853 11.0751 24.0187 11.3935 24.3812 12.0302C24.7469 12.6669 24.9297 13.5713 24.9297 14.7435C24.9297 15.9157 24.7469 16.8185 24.3812 17.452C24.0187 18.0854 23.3853 18.4021 22.4809 18.4021C21.5764 18.4021 20.9414 18.0854 20.5757 17.452C20.2132 16.8185 20.032 15.9157 20.032 14.7435C20.032 13.5713 20.2132 12.6669 20.5757 12.0302C20.9414 11.3935 21.5764 11.0751 22.4809 11.0751Z" fill="white" />
                        <path d="M27.0406 14.7435C27.0406 15.5598 27.1076 16.1851 27.2414 16.6193C27.3753 17.0503 27.6496 17.2658 28.0642 17.2658C28.4789 17.2658 28.7499 17.0503 28.8773 16.6193C29.0079 16.1851 29.0732 15.5598 29.0732 14.7435C29.0732 13.8881 29.0079 13.2546 28.8773 12.8432C28.7499 12.4318 28.4789 12.2261 28.0642 12.2261C27.6496 12.2261 27.3753 12.4318 27.2414 12.8432C27.1076 13.2546 27.0406 13.8881 27.0406 14.7435ZM28.0642 11.0751C28.9687 11.0751 29.6021 11.3935 29.9645 12.0302C30.3302 12.6669 30.5131 13.5713 30.5131 14.7435C30.5131 15.9157 30.3302 16.8185 29.9645 17.452C29.6021 18.0854 28.9687 18.4021 28.0642 18.4021C27.1598 18.4021 26.5247 18.0854 26.159 17.452C25.7966 16.8185 25.6154 15.9157 25.6154 14.7435C25.6154 13.5713 25.7966 12.6669 26.159 12.0302C26.5247 11.3935 27.1598 11.0751 28.0642 11.0751Z" fill="white" />
                        <path d="M31.5122 16.7663H32.9962V18.2258H31.5122V16.7663Z" fill="white" />
                        <path d="M33.9758 13.622C33.9758 12.8612 34.1945 12.2457 34.6321 11.7755C35.0728 11.3021 35.6442 11.0654 36.3463 11.0654C37.427 11.0654 38.1682 11.5437 38.5698 12.5004C38.7984 13.0424 38.9126 13.7558 38.9126 14.6407C38.9126 15.4994 38.8033 16.2161 38.5845 16.7908C38.1666 17.8846 37.3993 18.4315 36.2826 18.4315C35.7504 18.4315 35.272 18.2748 34.8476 17.9613C34.4231 17.6446 34.1798 17.1842 34.1178 16.5802H35.5087C35.5414 16.7891 35.6296 16.9589 35.7732 17.0895C35.9169 17.2201 36.1079 17.2854 36.3463 17.2854C36.8066 17.2854 37.1299 17.0308 37.316 16.5214C37.4172 16.2406 37.4809 15.8308 37.507 15.2921C37.3797 15.4521 37.2442 15.5745 37.1005 15.6594C36.8393 15.8161 36.5177 15.8945 36.1357 15.8945C35.5708 15.8945 35.0696 15.7002 34.6321 15.3117C34.1945 14.9198 33.9758 14.3566 33.9758 13.622ZM36.4393 14.7582C36.632 14.7582 36.8132 14.7027 36.983 14.5917C37.2997 14.3893 37.458 14.0383 37.458 13.5387C37.458 13.1371 37.3633 12.8187 37.174 12.5836C36.9879 12.3485 36.7315 12.231 36.405 12.231C36.1667 12.231 35.9626 12.2979 35.7928 12.4318C35.5251 12.6408 35.3912 12.995 35.3912 13.4946C35.3912 13.9158 35.4761 14.2325 35.6459 14.4448C35.8189 14.6537 36.0834 14.7582 36.4393 14.7582Z" fill="white" />
                        <path d="M40.9991 14.7435C40.9991 15.5598 41.066 16.1851 41.1999 16.6193C41.3337 17.0503 41.608 17.2658 42.0227 17.2658C42.4374 17.2658 42.7084 17.0503 42.8357 16.6193C42.9663 16.1851 43.0316 15.5598 43.0316 14.7435C43.0316 13.8881 42.9663 13.2546 42.8357 12.8432C42.7084 12.4318 42.4374 12.2261 42.0227 12.2261C41.608 12.2261 41.3337 12.4318 41.1999 12.8432C41.066 13.2546 40.9991 13.8881 40.9991 14.7435ZM42.0227 11.0751C42.9271 11.0751 43.5606 11.3935 43.923 12.0302C44.2887 12.6669 44.4715 13.5713 44.4715 14.7435C44.4715 15.9157 44.2887 16.8185 43.923 17.452C43.5606 18.0854 42.9271 18.4021 42.0227 18.4021C41.1182 18.4021 40.4832 18.0854 40.1175 17.452C39.7551 16.8185 39.5738 15.9157 39.5738 14.7435C39.5738 13.5713 39.7551 12.6669 40.1175 12.0302C40.4832 11.3935 41.1182 11.0751 42.0227 11.0751Z" fill="white" />
                        <path opacity="0.3" d="M51.1403 17.0466L53.3398 14.847L51.1403 12.6475" stroke="white" strokeWidth="2" />
                    </svg>
                </div>
                <img alt='' className='insta__circle' src={circle}></img>
            </div>
            <p className='insta-example__text'>Your Instagram catalog is filled with products</p>
        </motion.div>
    );
}

export default InstaExample