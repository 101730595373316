import useWindowSize from '../../../assets/hooks/useWindowSize';
import { Loop, LoopMobile, MonthlyVisitors, MonthlyVisitorsMobile, ProductsInStorage, ProductsInStorageMobile, Transaction, TransactionMobile, TransactionPerDay, TransactionPerDayMobile } from '../../../assets/icons/icons';
import './SuccessInNumbers.css';

import CountUp from 'react-countup';

function SuccessInNumbers() {
    const CARDS = [
        {
            title: 'Conversion rate',
            number: '1.7',
            subtitle: '%',
            icon: <Loop />,
            iconMobile: <LoopMobile />
        },
        {
            title: 'Monthly visitors',
            number: '88',
            subtitle: 'million',
            icon: <MonthlyVisitors />,
            iconMobile: < MonthlyVisitorsMobile />
        },
        {
            title: 'Average transaction',
            type: 'dollar',
            number: '62',
            icon: <Transaction />,
            iconMobile: <TransactionMobile />
        },
        {
            title: 'Transactions per day',
            type: 'thousand',
            number: '85',
            subtitle: 'over',
            icon: <TransactionPerDay />,
            iconMobile: <TransactionPerDayMobile />
        },
        {
            title: 'Products in storage',
            number: '37',
            subtitle: 'million',
            icon: <ProductsInStorage />,
            iconMobile: <ProductsInStorageMobile />
        },
    ]
    const window = useWindowSize();
    return (
        <div className='success-in-numbers'>
            <p className='success-in-numbers__title'>
                Success in numbers
            </p>
            <div className='success-in-numbers__cards-box'>
                {CARDS.map((item, i) => {
                    return (
                        <div style={{ gridArea: `card${i + 1}` }} className='success-in-numbers__card' key={item.title}>
                            <div className='success-in-numbers__card__info_visual'>
                                {window.width < 960 ? item.iconMobile : item.icon}
                                <div className={`success-in-numbers__card__info__visual-number-and-text success-in-numbers__card__info__visual-number-and-text_card${i + 1}`}>
                                    <div className='visual-count-number'>
                                        {item.type === 'dollar' ?
                                            <p className='success-in-numbers__card__info_visual__number' >$</p> : ''}

                                        <CountUp start={0} end={item.number} duration={3} delay={0} separator=',' enableScrollSpy={true} scrollSpyOnce={true}
                                            className='success-in-numbers__card__info_visual__number' />
                                        {item.subtitle === '%' ? <p className='success-in-numbers__card__info_visual__number'>.7</p> : null}
                                        {item.type === 'thousand' ?
                                            <p className='success-in-numbers__card__info_visual__number'>k</p> : null}
                                    </div>

                                    {/* <p className='success-in-numbers__card__info_visual__number'>{item.number}</p> */}
                                    <p className={`success-in-numbers__card__info_visual__text success-in-numbers__card__info_visual__text_card${i + 1}`}>{item.subtitle}</p>

                                </div>
                            </div>
                            <p className='success-in-numbers__card__info'>{item.title}</p>
                        </div>
                    )
                })}
            </div>
        </div>
    );
}

export default SuccessInNumbers