import { useEffect, useState } from "react";
import CustomInput from "../../CustomInput/CustomInput";
import MiniPreloader from "../../MiniPreloader/MiniPreloader";
import { motion } from 'framer-motion'
import './PopupJoin.css';

function PopupJoin({ valuesValidity, values, onChange, cleanForm, isOpen, setOpen }) {
    const [valuesCheck, setCheck] = useState(false);
    const [successTextVisible, setSuccessTextVisible] = useState(false);

    function handleChange() {
        setCheck(!valuesCheck)
    }

    const isDisabled = !(valuesValidity.email?.validState && valuesValidity.phone?.validState);
    const [preloaders, setPreloaders] = useState(false)
    const [file, setFile] = useState(null);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSubmit = async (evt) => {
        if (values.name && values.phone && values.email && values.why && values.position && !isDisabled && file) {
            evt.preventDefault();
            //             console.log(`Name: ${values.name}
            // Phone: ${values.phone}
            // Email: ${values.email}
            // LinkedIn: ${values.linkedin ? values.linkedin : '-'}
            // Position: ${values.position}
            // Why: ${values.why}
            // Comments: ${values.comments ? values.comments : '-'}
            // File:${file}`)
            console.log(values)
            cleanForm()
        } else {
            evt.preventDefault();
        }
    };

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isOpen]);

    const variants = {
        open: {
            opacity: 1,
            transition: {
                type: "spring",
                stiffness: 20,
                duration: 1.2
            }
        },
        closed: { opacity: 0 },
    }
    return (
        isOpen ?
            <motion.div
                animate={isOpen ? "open" : "closed"}
                variants={variants}
                className={`popup-join ${isOpen ? 'popup-join_opened' : ''}`}
            >
                <div className={`popup-join__form`} >
                    < div onClick={() => setOpen(!isOpen)
                    } className="popup-join__close-box" >
                        <svg className="popup-join__close" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M2 2L18 18" stroke="black" stroke-width="2.46154" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M18 2L2 18" stroke="black" stroke-width="2.46154" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div >
                    <form className="popup-join__form__container" onSubmit={handleSubmit} >
                        <p className="popup-join__form__form-title">Registration form</p>
                        <div className="popup-join__form__inputs-block">
                            <CustomInput
                                name="name"
                                label={'Full name'}
                                placeholder={'Full name'}
                                value={values.name}
                                onChange={onChange}
                            />
                            <CustomInput
                                name="email"
                                label={'E-mail'}
                                placeholder={'E-mail'}
                                value={values.email}
                                onChange={onChange}
                            />
                            <CustomInput
                                name="phone"
                                label={'Phone number'}
                                placeholder={'Phone number'}
                                value={values.phone}
                                onChange={onChange}
                            />
                            <CustomInput
                                name="linkedin_name"
                                label={'LinkedIn Profile (optional)'}
                                placeholder={'LinkedIn Profile (optional)'}
                                value={values.linkedin}
                                onChange={onChange}
                            />
                            <CustomInput
                                name="position"
                                label={'Position You’re Applying For'}
                                placeholder={'Position You’re Applying For'}
                                value={values.position}
                                onChange={onChange}
                            />
                            <CustomInput
                                name="why"
                                label={'Why Do You Want to Join Our Team?'}
                                placeholder={'Why Do You Want to Join Our Team?'}
                                value={values.why}
                                onChange={onChange}
                            />
                            <CustomInput
                                name="comments"
                                label={'Additional Comments (optional)'}
                                placeholder={'Additional Comments (optional)'}
                                value={values.comments}
                                onChange={onChange}
                                big={true}
                            />
                        </div>
                        <div className="popup-join__form__input-file">
                            <svg width="15" height="19" viewBox="0 0 15 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.7578 3.69531C14.0742 4.01172 14.2852 4.46875 14.2852 4.89062V16.5625C14.2852 17.5117 13.5117 18.25 12.5977 18.25H2.47266C1.52344 18.25 0.785156 17.5117 0.785156 16.5625V1.9375C0.785156 1.02344 1.52344 0.25 2.47266 0.25H9.64453C10.0664 0.25 10.5234 0.425781 10.8398 0.742188L13.7578 3.69531ZM12.457 4.75L9.78516 2.07812V4.75H12.457ZM2.47266 16.5625H12.5977V6.4375H8.94141C8.44922 6.4375 8.09766 6.08594 8.09766 5.59375V1.9375H2.47266V16.5625Z" fill="#2BBC5D" />
                            </svg>
                            Resume/CV Upload
                            <input
                                name="file-upload"
                                id="file-upload"
                                type="file"
                                className="popup-join__form__input-file__input"
                                onChange={handleFileChange}
                            />
                        </div>
                        <button className={`popup-join__form__submit-btn ${values.name && values.phone && values.email && !isDisabled && file ? 'popup-join__form__submit-btn_submit' : ''} `} type="submit">
                            {preloaders ? <MiniPreloader /> : 'Become our partner'}
                        </button>
                    </form >
                </div >
            </motion.div >
            : null
    )
}
export default PopupJoin