import {
  BAD_TITLE_VAR,
  CHILD_TYPE_TEXT,
  GOOD_TITLE_VAR,
  TITLE_VAR,
} from "../../../../assets/utils/constants";
import { renderArticleChildren } from "../../../../assets/utils/utils";
import "./TextElementWithVariables.css";

function TextElementWithVariables({ data }) {
  const children = parseTextChildren(data);

  function parseTextChildren(children) {
    return children.map((child) => {
      let childElement;

      // Обработка обычного текста
      if (child.type === CHILD_TYPE_TEXT) {
        // Разбиваем строку на массив
        childElement = child.text.split("\n").reduce((prevVal, item) => {
          if (item.includes(TITLE_VAR)) {
            prevVal.title = item.replace(/{{TITLE}}/g, "");
          } else {
            const children = [];
            const textArr = item.split(" ");
            const goodTitle = textArr.find((text) =>
              text.includes(GOOD_TITLE_VAR)
            );
            const badTitle = textArr.find((text) =>
              text.includes(BAD_TITLE_VAR)
            );
            const text = textArr
              .filter(
                (text) =>
                  !text.includes(GOOD_TITLE_VAR) &&
                  !text.includes(BAD_TITLE_VAR)
              )
              .join(" ");

            if (goodTitle)
              children.push({
                goodTitle: true,
                text: goodTitle.replace(/{{GOOD}}/g, ""),
                type: "text",
              });

            if (badTitle)
              children.push({
                badTitle: true,
                text: badTitle.replace(/{{BAD}}/g, ""),
                type: "text",
              });

            children.push({
              text: goodTitle || badTitle ? ` ${text}` : text,
              type: "text",
            });

            if (!prevVal.children) {
              prevVal.children = [children];
            } else {
              prevVal.children = [...prevVal.children, children];
            }
          }

          return prevVal;
        }, {});
      }

      return childElement;
    });
  }

  return children?.length > 0
    ? children.map((child, i) => (
      <div className="vars-element" key={`${child.title}-${i}`}>
        {child?.title ? (
          <p className={`${child?.children && (child?.children[0][0]?.goodTitle || child?.children[0][0]?.goodTitle) ? 'vars-element__text_center' : ''} vars-element__text bold`}>
            {child?.title}
          </p>
        ) : null}
        {child.children?.length > 0 ? (
          <div className="vars-element__list">
            {child.children.map((item, idx) => (
              <span className={`${item[0] && (item[0]?.badTitle || item[0]?.goodTitle) ? 'vars-element__text_center' : ''} vars-element__text`} key={`${item.text}-${idx}`}>
                {renderArticleChildren(item)}
              </span>
            ))}
          </div>
        ) : null}
      </div>
    ))
    : null;
}

export default TextElementWithVariables;
