import invite from "../../../assets/images/partners/overview/invite-img.webp";
import coupon from "../../../assets/images/partners/overview/coupon-img.webp";
import commission from "../../../assets/images/partners/overview/commission-img.webp";
import "./PartnersOverview.css";

const cards = [
  {
    title: "Invite your clients to create an online store on our platform",
    img: invite,
  },
  {
    title: `Give them your coupon code so that we know it's your clients`,
    img: coupon,
  },
  {
    title: `Once they use your code and pay for one of our plans, you'll get a 20% commission for each client`,
    img: commission,
  },
];

function PartnersOverview() {
  return (
    <div className="partners-overview">
      <p className="partners-overview__title">Program overview</p>
      <p className="partners-overview__text">
        Our program is accessible to a wide range of professions, from
        developers to digital agencies, and offers special service
        conditions for your clients. It equips you with the tools to help
        your audience launch and grow successful online businesses on our platform.
      </p>
      <p className="partners-overview__text">
        While you guide their journey, you'll unlock opportunities for
        competitive referral commissions and establish yourself as a
        leader in your field. Enrollment is completely free, so join today
        and unlock the potential to thrive together
      </p>

      <div className="partners-overview__cards-block">
        <p className="partners-overview__title">
          How you will make money&nbsp;with&nbsp;Sellavi
        </p>
        <ul className="partners-overview__list">
          {cards.map((item, i) => (
            <li className="partners-overview__item" key={`overview-${i}`}>
              <img
                className="partners-overview__card-img"
                src={item.img}
                alt=""
              />
              <p className="partners-overview__card-text">{item.title}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default PartnersOverview;
