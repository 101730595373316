import { LogoIcon } from "../../../../assets/icons/icons";
import "./PromoStart.css";

function PromoStart({ onSignupOpen }) {
  return (
    <div className="promo-start">
      <LogoIcon
        mainClassName="promo-start__logo"
        fillClassName="promo-start__logo-fill"
      />
      <p className="promo-start__text">
        START SELLING
        <br />
        ON SELLAVI
      </p>
      <button className="promo-start__btn" type="button" onClick={onSignupOpen}>
        RIGHT NOW!
      </button>

      <div className="promo-start__circle promo-start__circle_type_top-right" />
      <div className="promo-start__circle promo-start__circle_type_bottom-left" />
      <div className="promo-start__circle promo-start__circle_type_bottom-right" />
    </div>
  );
}

export default PromoStart;
