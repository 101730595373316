import { useEffect, useState } from "react";
import { productIdStorageKey } from "../../../../assets/utils/constants";
import { compareOptions } from "../../../../assets/utils/utils";


function ArticleLike({ articleObj }) {
    const [ckickLike, setcklickLike] = useState(true);
    let cartArray = JSON.parse(localStorage.getItem(productIdStorageKey)) || [];

    useEffect(() => {
        cartArray = JSON.parse(localStorage.getItem(productIdStorageKey)) || [];
    }, [ckickLike]);

    function handleAddToCart(item) {
        setcklickLike(!ckickLike)
        let cartArray = JSON.parse(localStorage.getItem(productIdStorageKey)) || [];

        function isSameItemOptions(cartItem, newItem) {
            if (
                !newItem.options ||
                newItem.options.length === 0 ||
                !cartItem.options ||
                cartItem.options.length === 0
            ) {
                return true; // Treat as same if either item doesn't have options
            }
            const comparisonResult = compareOptions(
                newItem.options,
                cartItem.options
            );
            return comparisonResult.differences.length === 0;
        }
        const existingCartItemIndex = cartArray.findIndex(
            (cartItem) => cartItem.id === item.id && isSameItemOptions(cartItem, item)
        );
        if (existingCartItemIndex !== -1) {
            cartArray[existingCartItemIndex].count += 1;
        } else {
            const newItem = {
                id: item.id,
                count: item.likes_count + 1,
            };
            cartArray.push(newItem);
        }
        localStorage.setItem(productIdStorageKey, JSON.stringify(cartArray));
    }

    function handleRemoveFromCart(itemToRemove, isAll) {
        setcklickLike(!ckickLike)

        let cartArray = JSON.parse(localStorage.getItem(productIdStorageKey)) || [];

        function isSameItemOptions(cartItem, item) {
            if (!item.options || item.options.length === 0 || !cartItem.options || cartItem.options.length === 0) {
                return true; // Consider items the same if either has no options
            }
            const comparisonResult = compareOptions(item.options, cartItem.options);
            return comparisonResult.differences.length === 0;
        }
        const itemIndex = cartArray.findIndex(cartItem =>
            cartItem.id === itemToRemove.id && isSameItemOptions(cartItem, itemToRemove));

        if (isAll) {
            cartArray = cartArray.filter(cartItem =>
                !(cartItem.id === itemToRemove.id && isSameItemOptions(cartItem, itemToRemove)));
        } else {
            if (itemIndex !== -1) {
                if (cartArray[itemIndex].count > 1) {
                    cartArray[itemIndex].count -= 1;

                } else {
                    cartArray.splice(itemIndex, 1);
                }
            }
        }
        localStorage.setItem(productIdStorageKey, JSON.stringify(cartArray));
    }

    console.log(cartArray)
    return (
        <div
            className="article__like-btn"
            onClick={(evt) => evt.stopPropagation()}>
            {cartArray.length > 0 ?
                <button
                    onClick={() => handleRemoveFromCart(articleObj, true)}
                    className='blog__main-grid__like-box'>
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.594299" y="0.618652" width="38.8727" height="38.8727" rx="19.4364" fill="url(#paint0_linear_813_854)" />
                        <path d="M20.0305 29.071L19.7715 28.8562C19.2804 28.4456 18.6156 28.0003 17.8449 27.4855C14.8428 25.4751 10.7322 22.7242 10.7322 18.0339C10.7322 15.1361 13.0899 12.7783 15.9878 12.7783C17.5622 12.7783 19.0372 13.4795 20.0305 14.6781C21.0239 13.4795 22.4988 12.7783 24.0733 12.7783C26.9711 12.7783 29.3289 15.1361 29.3289 18.0339C29.3289 22.7242 25.2182 25.4751 22.2162 27.4855C21.4455 28.0003 20.7807 28.4456 20.2895 28.8562L20.0305 29.071Z" fill="white" />
                        <defs>
                            <linearGradient id="paint0_linear_813_854" x1="20.0307" y1="0.618652" x2="20.0307" y2="39.4914" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#FF56A6" />
                                <stop offset="1" stopColor="#FF238C" />
                            </linearGradient>
                        </defs>
                    </svg>
                    {JSON.parse(localStorage.getItem(productIdStorageKey)).map((itemIdCard, i) => {
                        return (
                            articleObj.id === itemIdCard.id ?
                                <div className='blog__main-grid__like'>{itemIdCard.count}</div>
                                : null
                        )
                    })
                    }
                </button>
                :
                <button
                    onClick={() => handleAddToCart(articleObj)}
                    className='blog__main-grid__like-box'>
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.594299" y="0.618652" width="38.8727" height="38.8727" rx="19.4364" fill="url(#paint0_linear_813_854)" />
                        <path d="M20.0305 29.071L19.7715 28.8562C19.2804 28.4456 18.6156 28.0003 17.8449 27.4855C14.8428 25.4751 10.7322 22.7242 10.7322 18.0339C10.7322 15.1361 13.0899 12.7783 15.9878 12.7783C17.5622 12.7783 19.0372 13.4795 20.0305 14.6781C21.0239 13.4795 22.4988 12.7783 24.0733 12.7783C26.9711 12.7783 29.3289 15.1361 29.3289 18.0339C29.3289 22.7242 25.2182 25.4751 22.2162 27.4855C21.4455 28.0003 20.7807 28.4456 20.2895 28.8562L20.0305 29.071Z" fill="white" />
                        <defs>
                            <linearGradient id="paint0_linear_813_854" x1="20.0307" y1="0.618652" x2="20.0307" y2="39.4914" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#FF56A6" />
                                <stop offset="1" stopColor="#FF238C" />
                            </linearGradient>
                        </defs>
                    </svg>
                    <div className='blog__main-grid__like'>{articleObj.likes_count}</div>
                </button>
            }
        </div>
    )
}
export default ArticleLike