import { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import { DropArrowIcon } from '../../../assets/icons/price';
import { getRandomId } from '../../../assets/utils/utils';
import './FAQ.css';
import { INFO_EMAIL } from '../../../assets/utils/constants';

const QUESTIONS = [
    {
        _id: getRandomId(),
        question: 'Do&nbsp;I&nbsp;need to&nbsp;be&nbsp;a&nbsp;designer or&nbsp;developer to&nbsp;use Sellavi?',
        answer: `Absolutely not! Sellavi&rsquo;s intuitive interface lets you customize your online store in&nbsp;minutes, even without programming knowledge. This means you can focus on&nbsp;what matters most&nbsp;&mdash; your products and customers. 
        
        Need a&nbsp;hand? Our comprehensive knowledge base offers answers to&nbsp;most questions, and our friendly Technical Support team is&nbsp;always available via the green chat icon in&nbsp;the bottom right corner of&nbsp;your store.`
    },
    {
        _id: getRandomId(),
        question: 'Can I&nbsp;migrate my&nbsp;store to&nbsp;Sellavi?',
        answer: 'Sure! Our technical experts team is&nbsp;always ready and equipped to&nbsp;assist you with moving your online store to&nbsp;our platform. All we&nbsp;need from you is&nbsp;your product and client export table, and your migration will be&nbsp;done in&nbsp;no&nbsp;time! Don&rsquo;t worry, you will not lose any data during this process.'
    },
    {
        _id: getRandomId(),
        question: 'How do&nbsp;I upgrade my&nbsp;plan?',
        answer: `Upgrading your Sellavi plan is&nbsp;easy! Just follow these 4&nbsp;steps:

        1) Log in&nbsp;to&nbsp;your Admin Panel.
        2) Click on&nbsp;your store number located at&nbsp;the bottom left corner.
        3) Select &laquo;Manage your plan&raquo; from the menu. This will open your plan options.
        4) Choose the plan* you&rsquo;d like to&nbsp;upgrade to&nbsp;and confirm your selection.
        
        That&rsquo;s it! Your plan will be&nbsp;upgraded immediately.
        
        *You can also combine our plans to&nbsp;your liking. Reach out to&nbsp;the support team to&nbsp;know more!`
    },
    {
        _id: getRandomId(),
        question: 'Can I&nbsp;use my&nbsp;own domain?',
        answer: 'Of&nbsp;course! You can add an&nbsp;existing domain to&nbsp;your online store&nbsp;&mdash; regardless of&nbsp;the plan you choose.'
    },
    {
        _id: getRandomId(),
        question: 'Can I&nbsp;manage my&nbsp;store from a&nbsp;mobile device?',
        answer: 'Yes! Sellavi&rsquo;s platform is&nbsp;mobile-first, ensuring convenience for both customers and merchants. The responsive design focuses on&nbsp;the mobile version, enhancing the shopping experience and boosting sales.'
    },
    {
        _id: getRandomId(),
        question: 'I&nbsp;sell a&nbsp;large amount of&nbsp;products. Is&nbsp;there a&nbsp;limit on&nbsp;hosting and the number of&nbsp;products that can be&nbsp;uploaded to&nbsp;the store?',
        answer: `Sellavi boasts unlimited hosting and allows an&nbsp;unrestricted number of&nbsp;product uploads for each store. Whether you sell a&nbsp;handful or&nbsp;an&nbsp;extensive range of&nbsp;products, our platform has you covered!`
    },


    {
        _id: getRandomId(),
        question: 'Are there integrated analytical tools to&nbsp;track my&nbsp;business processes?',
        answer: `Absolutely! Sellavi provides an&nbsp;intuitive dashboard displaying real-time income, sales reports, and essential statistics to&nbsp;keep you informed about your business.`
    },
    {
        _id: getRandomId(),
        question: 'Do&nbsp;I&nbsp;have to&nbsp;pay for the traffic&nbsp;I generate and for data storage?',
        answer: `We&nbsp;don&rsquo;t charge money for the traffic you generate. Also, regardless of&nbsp;which plan you have, you can download and store an&nbsp;unlimited amount of&nbsp;information and data for free.`
    },

    {
        _id: getRandomId(),
        question: 'Are non-profit organizations eligible for discounts?',
        answer: `Yes, non-profit organizations can receive a&nbsp;discount. Please send&nbsp;us an&nbsp;email via <a class="faq__item-info-answer-link" href="mailto:${INFO_EMAIL}" target="_blank">${INFO_EMAIL}</a> and tell&nbsp;us about your organization. We will provide you with a special offer.`
    },
]

function FAQ() {

    const [openItemId, setOpenItemId] = useState(QUESTIONS[0]._id);

    const toggleItem = (id) => {
        if (openItemId === id) {
            setOpenItemId(null);
        } else {
            setOpenItemId(id);
        }
    };

    return (
        <div className='faq'>
            <h4 className='faq__title'>Frequently asked questions</h4>
            <p className='faq__subtitle'>Everything you need to know about the platform and payments</p>
            <div className='faq__items'>
                {QUESTIONS.map((item, i) => (
                    <button className={`faq__item ${openItemId === item._id ? 'faq__item_selected' : ''}`} type='button' key={`faq__item${item._id}`} onClick={() => toggleItem(item._id)}>
                        <div className='faq__item-info'>
                            <p className='faq__item-info-question'
                                dangerouslySetInnerHTML={{
                                    __html: item.question,
                                }}
                            ></p>
                            <AnimatePresence>
                                {openItemId === item._id && (
                                    <motion.div
                                        initial={{ opacity: 0, height: 0, y: -20, }}
                                        animate={{ opacity: 1, height: 'auto', y: 0, }}
                                        exit={{ opacity: 0, height: 0, y: -20, }}
                                        transition={{ duration: 0.3 }}
                                    >
                                        <p className='faq__item-info-answer'
                                            dangerouslySetInnerHTML={{
                                                __html: item.answer,
                                            }}
                                        ></p>
                                    </motion.div>
                                )}
                            </AnimatePresence>
                        </div>
                        <DropArrowIcon
                            mainClassName={'faq__item-arrow'}
                            strokeClassName={'faq__item-arrow-stroke'}
                        />
                    </button>
                ))}

            </div>
        </div>
    );
}

export default FAQ