import { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import useScrollSpy from "../../../assets/hooks/useScrollSpy";
import useScrollDirection from "../../../assets/hooks/useScrollDirection";
import useWindowSize from "../../../assets/hooks/useWindowSize";
import { MAIN_NAV_LINKS } from "../../../assets/utils/constants";
import "./MainNav.css";

function MainNav({ onSignupOpen }) {
  const sections = useRef([]);
  const { pathname, hash, key } = useLocation();
  const [activeLink, setActiveLink] = useState(hash ? hash : "start");
  const [ids, setIds] = useState([]);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isPageTop, setIsPageTop] = useState(true);
  const activeId = useScrollSpy({ ids, offset: 100 });
  const scrollDirection = useScrollDirection();
  const { width } = useWindowSize();

  useEffect(() => {
    const updateScrollPosition = () => {
      const scrollY = window.pageYOffset;
      const isTop = scrollY <= 150;
      setIsPageTop(isTop);
    };

    window.addEventListener("scroll", updateScrollPosition);
    return () => {
      window.removeEventListener("scroll", updateScrollPosition);
    };
  });

  useEffect(() => {
    setIsCollapsed(!isPageTop);
  }, [isPageTop]);

  useEffect(() => {
    const data = [];
    sections.current = document
      .querySelectorAll("[data-section]")
      .forEach((section) => data.push(section.id));
    setIds(data);
  }, []);

  useEffect(() => {
    if (hash === "") window.scrollTo(0, 0);
    else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        setActiveLink(id);
        if (element) {
          element.scrollIntoView({
            block: "start",
            behavior: "smooth",
          });
        }
      }, 0);
    }
  }, [pathname, hash, key]);

  useEffect(() => {
    if (activeId) setActiveLink(activeId);
  }, [activeId]);

  return (
    <motion.nav
      className="main-nav"
      style={{ left: width > 1920 ? (width - 1920) / 2 + 56 : 56 }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <motion.div
        className="main-nav__links"
        animate={{ height: isCollapsed ? "20px" : "200px" }}
        transition={{ duration: 0.2 }}
      >
        <AnimatePresence mode="popLayout" initial={false}>
          {isPageTop ? (
            <motion.ul
              className="main-nav__links-list"
              key="visible-top"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.1 }}
            >
              {MAIN_NAV_LINKS.map((link, i) => (
                <li className="main-nav__link-item" key={`visible-top-${i}`}>
                  <Link
                    key={`main-nav-link-${i}`}
                    to={`#${link.path}`}
                    className={`main-nav__link ${
                      activeLink === link.path ? "main-nav__link_active" : ""
                    }`}
                  >
                    {link.name}
                  </Link>
                </li>
              ))}
            </motion.ul>
          ) : !isCollapsed ? (
            <motion.ul
              className="main-nav__links-list"
              key="visible-expand"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.1 }}
            >
              {MAIN_NAV_LINKS.map((link, i) => (
                <li className="main-nav__link-item" key={`visible-expand-${i}`}>
                  <Link
                    key={`main-nav-link-${i}`}
                    to={`#${link.path}`}
                    className={`main-nav__link ${
                      activeLink === link.path ? "main-nav__link_active" : ""
                    }`}
                    onClick={
                      link.path !== "start"
                        ? () => setIsCollapsed(true)
                        : undefined
                    }
                  >
                    {link.name}
                  </Link>
                </li>
              ))}
            </motion.ul>
          ) : (
            <motion.div
              className="main-nav__link-item"
              key="collapse"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.1 }}
            >
              <button
                className="main-nav__link main-nav__link_collapsed"
                type="button"
                onClick={() => setIsCollapsed(false)}
              >
                <AnimatePresence mode="popLayout" initial={false}>
                  <motion.p
                    className="main-nav__link-text"
                    key={activeLink}
                    initial={{
                      y: scrollDirection === "down" ? 15 : -15,
                      opacity: 0,
                    }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{
                      y: scrollDirection === "down" ? -15 : 15,
                      opacity: 0,
                    }}
                    transition={{ duration: 0.1 }}
                  >
                    {
                      MAIN_NAV_LINKS.find((link) => link.path === activeLink)
                        .name
                    }
                  </motion.p>
                </AnimatePresence>
              </button>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
      <button
        className="main-nav__link-btn"
        type="button"
        onClick={onSignupOpen}
      >
        Start free trial
      </button>
    </motion.nav>
  );
}

export default MainNav;
