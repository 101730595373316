import { useEffect, useRef, useState } from "react";
import "./InputSelect.css";
import { DropdownRectangle } from "../../../assets/icons/icons";
import { motion } from 'framer-motion';
import { PHONE_CODES_LIST } from "../../../assets/utils/constants";

function InputSelect({
  name,
  value,
  selectList,
  onChange,
  onOpenDropdown,
  onCloseDropdown,
  isOpen,
}) {
  const [inputText, setInputText] = useState("+");
  const overlay = useRef();

  useEffect(() => {
    function handleOverlayClose(evt) {
      if (overlay.current === evt.target) {
        onCloseDropdown();
      }
    }

    document.addEventListener("mousedown", handleOverlayClose);
    return () => {
      document.removeEventListener("mousedown", handleOverlayClose);
    };
  }, [onCloseDropdown]);

  const [filter, setFiltered] = useState(PHONE_CODES_LIST)

  useEffect(() => {
    console.log(inputText)
    if (inputText === '+') {
      setFiltered(PHONE_CODES_LIST)
    }
    else if (selectList.filter((item) => item.value.toLowerCase().includes(inputText.toLowerCase())).length === 0) {
      setFiltered([])
      setFiltered(PHONE_CODES_LIST)
    }
    else if (selectList.filter((item) => item.value.toLowerCase() === (inputText.toLowerCase())).length === 0 && selectList.filter((item) => item.value.toLowerCase().includes(inputText.toLowerCase()))) {
      setFiltered([])
      const filteredList = selectList.filter((item) => item.value.toLowerCase().includes(inputText.toLowerCase()))
      setFiltered(filteredList)
    }
    else {
      setFiltered([])
      const filteredList = selectList.filter((item) => item.value.toLowerCase() === (inputText.toLowerCase()))
      setFiltered(filteredList)
    }
  }, [inputText])

  function handleSelect(item) {
    onChange({
      target: {
        value: item,
        name,
      },
    });
    setInputText(item.value); // Set input text to selected item
    onCloseDropdown();
  }

  function handleInputChange(event) {
    setInputText(event.target.value);
    onOpenDropdown(); // Open the dropdown when typing
  }

  return (
    <div className="select">
      {isOpen ? (
        <>
          <motion.input
            // initial={{ opacity: 0 }}
            // animate={{ opacity: 1 }}
            // transition={{ duration: 0.5 }}
            className="select__value-box"
            autoFocus
            value={inputText}
            onChange={handleInputChange}
          />
          <motion.svg
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="select__value-box__icon" width="25" height="15" viewBox="0 0 25 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.1759 1.23897C11.8029 0.559632 12.9003 0.559632 13.5274 1.23897L23.5608 11.2723C24.2401 11.8994 24.2401 12.9968 23.5608 13.6239C22.9337 14.3032 21.8363 14.3032 21.2092 13.6239L12.3778 4.79244L3.4941 13.6239C2.86701 14.3032 1.76962 14.3032 1.14253 13.6239C0.463194 12.9968 0.463194 11.8994 1.14253 11.2723L11.1759 1.23897Z" fill="#35C650" />
          </motion.svg>
        </>
      ) : (
        <button
          className="select__value-box"
          type="button"
          onClick={onOpenDropdown}
        >
          <motion.img
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="select__value-icon" src={value?.icon} alt="" />
          <motion.p initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="select__value">{value?.value ? value.value : ""}
          </motion.p>
          <motion.svg
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="select__value-box__icon" width="25" height="15" viewBox="0 0 25 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.8241 13.8719C13.1971 14.5512 12.0997 14.5512 11.4726 13.8719L1.43925 3.83854C0.759908 3.21145 0.759908 2.11406 1.43925 1.48697C2.06633 0.807635 3.16373 0.807635 3.79081 1.48697L12.6222 10.3184L21.5059 1.48698C22.133 0.807637 23.2304 0.807637 23.8575 1.48698C24.5368 2.11406 24.5368 3.21146 23.8575 3.83854L13.8241 13.8719Z" fill="#AAAAAA" />
          </motion.svg>
        </button>
      )}
      <div
        className={`select__dropdown ${isOpen ? "select__dropdown_opened" : ""
          }`}
      >
        <div className="select__dropdown-container">
          <DropdownRectangle
            mainClassName="select__dropdown-arrow"
            fillClassName="select__dropdown-arrow-fill"
            strokeClassName="select__dropdown-arrow-stroke"
          />
          <ul className="select__list">
            {filter?.map((item) => (
              <li key={item.icon} className="select__item">
                <button
                  className={`select__item-btn ${item._id === value?._id ? "select__item-btn_selected" : ""
                    }`}
                  type="button"
                  onClick={() => handleSelect(item)}
                >
                  <img className="select__item-icon" src={item?.icon} alt="" />
                  <p className="select__item-text">{item.value}</p>
                </button>
              </li>
            ))}
          </ul>
        </div>
        <div className="select__dropdown-overlay" ref={overlay} />
      </div>
    </div>
  );
}

export default InputSelect;
