import { useContext } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Mousewheel } from "swiper/modules";
import { ThemeContext } from "../../../assets/contexts/themeContext";
import useWindowSize from "../../../assets/hooks/useWindowSize";
import developers from "../../../assets/images/partners/target/developers-slide.webp";
import design from "../../../assets/images/partners/target/design-slide.webp";
import copywriters from "../../../assets/images/partners/target/copywriters-slide.webp";
import business from "../../../assets/images/partners/target/business-slide.webp";
import marketing from "../../../assets/images/partners/target/marketing-slide.webp";
import trainers from "../../../assets/images/partners/target/trainers-slide.webp";
import program from "../../../assets/images/partners/target/program-slide.webp";
import entrepreneurs from "../../../assets/images/partners/target/entrepreneurs-slide.webp";
import seo from "../../../assets/images/partners/target/seo-slide.webp";
import sem from "../../../assets/images/partners/target/sem-slide.webp";
import social from "../../../assets/images/partners/target/social-slide.webp";
import managers from "../../../assets/images/partners/target/managers-slide.webp";
import influencers from "../../../assets/images/partners/target/influencers-slide.webp";
import commerce from "../../../assets/images/partners/target/commerce-slide.webp";
import bg from "../../../assets/images/partners/target/bg.webp";
import bgDark from "../../../assets/images/partners/target/bg-dark-theme.webp";
import { THEME_LIGHT_TYPE } from "../../../assets/utils/constants";
import "./PartnersTarget.css";
import "swiper/css";

const cards = [
  {
    title: "Website Developers",
    img: developers,
  },
  {
    title: "Design Professionals",
    img: design,
  },
  {
    title: "Copywriters and Content Creators",
    img: copywriters,
  },
  {
    title: "Business Consultants",
    img: business,
  },
  {
    title: "Marketing Consultants",
    img: marketing,
  },
  {
    title: "Personal Trainers",
    img: trainers,
  },
  {
    title: "Affiliate Program Specialists",
    img: program,
  },
  {
    title: "Entrepreneurs",
    img: entrepreneurs,
  },
  {
    title: "SEO Agencies",
    img: seo,
  },
  {
    title: "SEM (Search Engine Marketing) Companies",
    img: sem,
  },
  {
    title: "Social Media Marketing Companies",
    img: social,
  },
  {
    title: "Customer Club Managers",
    img: managers,
  },
  {
    title: "Influencers",
    img: influencers,
  },
  {
    title: "E-commerce Site Managers",
    img: commerce,
  },
];

const autoplayTimer = 2000;
const animationSwiperSpeed = 1000;

function PartnersTarget() {
  const { theme } = useContext(ThemeContext);
  const { width } = useWindowSize();

  return (
    <div className="partners-target">
      <img
        className="partners-target__bg"
        src={theme === THEME_LIGHT_TYPE ? bg : bgDark}
        alt=""
      />
      <p className="partners-target__title">Who is our program&nbsp;for?</p>
      <Swiper
        className="partners-target__swiper"
        slidesPerView={"auto"}
        centeredSlides={true}
        spaceBetween={36}
        loop={true}
        speed={animationSwiperSpeed}
        autoplay={{ delay: autoplayTimer, stopOnLastSlide: true }}
        modules={[Mousewheel, Autoplay]}
        preventInteractionOnTransition={true}
        mousewheel={{ forceToAxis: true }}
      >
        {cards.map((item, i) => (
          <SwiperSlide
            className="partners-target__slide"
            key={`partners-slide-${i}`}
          >
            <div
              className="partners-target__slide-container"
              style={{
                marginTop:
                  (i + 1) % 2 === 0 ? (width <= 780 ? "30px" : "45px") : 0,
              }}
            >
              <img
                className="partners-target__slide-img"
                src={item.img}
                alt=""
              />
              <div className="partners-target__title-box">
                <p className="partners-target__slide-title">{item.title}</p>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default PartnersTarget;
