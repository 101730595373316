import { AdminPanelIcon, AnaliticsIcon, ClientsIcon, CommunicationsIcon, DesignIcon, MarketingAndAdsIcon, OnlineStoreIcon, OrdersIcon, PaymentsIcon, ProductPricesIcon, SalesIcon, SecurityIcon, ServiceAndSupportIcon, ShippingIcon } from "../icons/compareFeatures";
import { getRandomId } from "./utils";

const SALES_CHANNELS = {
    title: 'Sales Channels',
    icon: SalesIcon,
    _id: getRandomId(),
    items: [
        {
            title: 'Online store',
            standart_value: {
                type: 'text',
                value: 'Sellavi',
            },
            plus_value: {
                type: 'text',
                value: 'Sellavi Plus',
            },
            _id: getRandomId(),
        },
        {
            title: 'Instagram Shop',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Facebook Shop',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'TikTok Shop',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Google Shopping',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'WhatsApp',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'SMS',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Newsletters',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
    ],
}

const ONLINE_STORES = {
    title: 'Online Store',
    icon: OnlineStoreIcon,
    _id: getRandomId(),
    items: [
        {
            title: 'Order management',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Product management',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Inventory tracking',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Client management',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Booking management',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Product review management',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Design templates',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Multi-language online store',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Personal accounts for customers',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Buy/Connect your own Domain',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Free SSL certificate',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Product sorting and filtering options',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Persistent cart',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Single-page checkout',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Guest checkout',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Custom fields at checkout',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Purchase restriction mode',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'GDPR compliance',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
    ],
}


const ADMIN_PANEL = {
    title: 'Admin Panel',
    icon: AdminPanelIcon,
    _id: getRandomId(),
    items: [
        {
            title: 'Real-time dashboard',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Store extensions',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Multi-language admin panel',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Multi-user admin panel',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'User roles with 100+ permissions',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Maintenance mode',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: "Access to Sellavi's API",
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Access to Webhooks',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
    ],
}


const PRODUCT_PRICES_AND_INVENTORY = {
    title: 'Products, Prices, and Inventory',
    icon: ProductPricesIcon,
    _id: getRandomId(),
    items: [
        {
            title: 'Unlimited products',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Unlimited categories',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Unlimited media storage',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Digital products',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Advanced inventory tracking',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Complementary products',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Related products',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Product rating and reviews',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Hidden products',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Hidden categories',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Hidden prices',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Scheduled discounts',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Wholesale prices',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Multi-currency online store',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Product options',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Product filters',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Stickers',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Brands',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Import / Export',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Bulk update',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
    ],
}


const SHIPPING_AND_DELIVERIES = {
    title: 'Shipping & Delivery',
    icon: ShippingIcon,
    _id: getRandomId(),
    items: [
        {
            title: 'Built-in shipping services',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'International shipping',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Real-time shipping rates',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Custom delivery methods',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Conditional delivery',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Zonal delivery',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Deliveries, bound to products',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Quick delivery order from the interface',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Built-in shipment tracking system',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Fulfillment',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Warehouses',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Pickup points',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },

    ],
}


const PAYMENTS = {
    title: 'Payments',
    icon: PaymentsIcon,
    _id: getRandomId(),
    items: [
        {
            title: 'Online payment solution',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Apple Pay and Google Pay',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Payment gateway setup',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Special rate on transaction fees',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Online invoicing',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Cash on delivery',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Bank wire',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Recurring payments',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Custom fields on checkout',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
    ],
}


const DESIGN = {
    title: 'Design',
    icon: DesignIcon,
    _id: getRandomId(),
    items: [
        {
            title: 'Variety of design templates ',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Modular page builder',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'CSS/JS editors',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Video banners',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        // {
        //     title: 'Online invoicing',
        //     standart_value: {
        //         type: 'icon',
        //         value: true,
        //     },
        //     plus_value: {
        //         type: 'icon',
        //         value: true,
        //     },
        //     _id: getRandomId(),
        // },
        {
            title: 'Content management tool',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        // {
        //     title: 'Phone payment',
        //     standart_value: {
        //         type: 'icon',
        //         value: true,
        //     },
        //     plus_value: {
        //         type: 'icon',
        //         value: true,
        //     },
        //     _id: getRandomId(),
        // },
        // {
        //     title: 'Recurring subscriptions',
        //     standart_value: {
        //         type: 'icon',
        //         value: true,
        //     },
        //     plus_value: {
        //         type: 'icon',
        //         value: true,
        //     },
        //     _id: getRandomId(),
        // },
        {
            title: 'Quick styling options',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Settings for Mobile display',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Personalized store design',
            standart_value: {
                type: 'icon',
                value: false,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
    ],
}



const ORDERS = {
    title: 'Orders',
    icon: OrdersIcon,
    _id: getRandomId(),
    items: [
        {
            title: 'Unlimited orders',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Direct order management',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'No additional fees',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Manual order creation',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Order editor',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Order statuses',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Digital invoice generator',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Notifications about new orders via email, SMS, and Telegram',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Booking management',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Order export',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: '30-day abandoned cart lifetime',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
    ],
}


const CLIENTS = {
    title: 'Clients',
    icon: ClientsIcon,
    _id: getRandomId(),
    items: [
        {
            title: 'Management system',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Purchase history',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Personal accounts for customers',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Quick order repeat',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Multiple saved addresses',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Customer groups',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Personalized purchase experience',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Custom fields',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Import / Export',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Bulk update',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
    ],
}


const MARKETING_AND_ADS = {
    title: 'Marketing & Ads',
    icon: MarketingAndAdsIcon,
    _id: getRandomId(),
    items: [
        {
            title: 'Built-in SEO',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Google Ads',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Facebook Pixel',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Facebook Conversions API',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Promotional scenarios',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Discounts and promo codes',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Promo code generator',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Gift vouchers',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Sales pages',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Built-in blog',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Automatic "Sale" category',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'SEO meta-tags editing',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Robots.txt editor',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Autogenerated sitemap.xml',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: '301 redirects management',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },

    ],
}



const ANALITICS = {
    title: 'Analytics',
    icon: AnaliticsIcon,
    _id: getRandomId(),
    items: [
        {
            title: 'Real-time dashboard',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Reports on abandoned carts',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Promo code usage reports',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Google Analytics GA4',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Google Analytics API',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Google tag manager',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
    ],
}



const SECURITY = {
    title: 'Security',
    icon: SecurityIcon,
    _id: getRandomId(),
    items: [
        {
            title: 'Built-in Firewall',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'PCI DSS Level 1',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'SSL certificate',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'DDoS protection',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Spam protection',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Online store monitoring 24/7',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'GDPR compliance',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
    ],
}


const COMMUNICATIONS = {
    title: 'Communications',
    icon: CommunicationsIcon,
    _id: getRandomId(),
    items: [
        {
            title: 'WhatsApp widget',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Facebook widget',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Message templates',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Personalized dynamic customer data',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Order status update notifications',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'SMS notifications',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Email notifications',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Email marketing tools connection',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Custom widgets connection',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
    ],
}



const SERVICE_AND_SUPPORT = {
    title: 'Service & Support',
    icon: ServiceAndSupportIcon,
    _id: getRandomId(),
    items: [
        {
            title: 'Large knowledge base',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Live chat support',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Email support',
            standart_value: {
                type: 'icon',
                value: true,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Multichannel support',
            standart_value: {
                type: 'icon',
                value: false,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Personal e-commerce specialist',
            standart_value: {
                type: 'icon',
                value: false,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
        {
            title: 'Rapid first-line support',
            standart_value: {
                type: 'icon',
                value: false,
            },
            plus_value: {
                type: 'icon',
                value: true,
            },
            _id: getRandomId(),
        },
    ],
}

export const PLAN_FEATURES = [
    SALES_CHANNELS,
    ONLINE_STORES,
    ADMIN_PANEL,
    PRODUCT_PRICES_AND_INVENTORY,
    SHIPPING_AND_DELIVERIES,
    PAYMENTS,
    DESIGN,
    ORDERS,
    CLIENTS,
    MARKETING_AND_ADS,
    ANALITICS,
    SECURITY,
    COMMUNICATIONS,
    SERVICE_AND_SUPPORT,
]


