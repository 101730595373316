import './Bennefits.css';

import card_1 from '../../../assets/images/bennefits/card-1.png'
import card_2 from '../../../assets/images/bennefits/card-2.png'
import card_3 from '../../../assets/images/bennefits/card-3.png'
import card_4 from '../../../assets/images/bennefits/card-4.png'
import card_5 from '../../../assets/images/bennefits/card-5.png'
import card_6 from '../../../assets/images/bennefits/card-6.png'

const CARDS = [
    {
        img: card_1,
        text: 'Unlimited hosting and data storage space',
    },
    {
        img: card_2,
        text: 'Integration with powerful marketing tools',
    },
    {
        img: card_3,
        text: 'Comprehensive data protection and secure payments',
    },
    {
        img: card_4,
        text: 'Live technical support',
    },
    {
        img: card_5,
        text: 'Built-in logistics solution',
    },
    {
        img: card_6,
        text: 'Built-in online payment solutions',
    },
]

function Bennefits({ onSignupOpen }) {
    return (
        <div className='bennefits'>
            <h4 className='bennefits__title'>With any plan, you get</h4>
            <p className='bennefits__subtitle'>No small print: you get what you see</p>
            <div className='bennefits__items'>
                {CARDS.map((item, i) => (
                    <div className='bennefits__item' key={`bennefits__item${i}`}>
                        <img className='bennefits__item-img' src={item.img} alt='' />
                        <p className='bennefits__item-text'>{item.text}</p>
                    </div>
                ))}

            </div>
            <button className='bennefits__btn' type='button' onClick={onSignupOpen}>
                Start 14-day free trial
            </button>
        </div>
    );
}

export default Bennefits