import ScrollToTop from '../../assets/hooks/scrollToTop';
// import HeadBanner from '../Main/HeadBanner/HeadBanner';
import './AboutUs.css';
import Departments from './Departments/Departments';
import Global from './Global/Global';
import HeaderBannerAboutUs from './HeaderBannerAboutUs/HeaderBannerAboutUs';
import OurPartners from './OurPartners/OurPartners';
import OurStorySteps from './OurStorySteps/OurStorySteps';
import SuccessInNumbers from './SuccessInNumbers/SuccessInNumbers';

function AboutUs({ valuesValidity, values, onChange, cleanForm }) {
    return (
        <div className='about-us'>
            <ScrollToTop />
            <div className='about-us__box'>
                {/* <HeadBanner/> */}
                <HeaderBannerAboutUs />
                <SuccessInNumbers />
                <OurStorySteps />
                <Global />
                <Departments valuesValidity={valuesValidity} values={values} onChange={onChange} cleanForm={cleanForm} />
                <OurPartners />
            </div>
        </div>
    );
}

export default AboutUs