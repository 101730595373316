import './OurStorySteps.css';
import house from '../../../assets/images/aboutUs/house.png'
import { motion, useAnimation, useInView } from 'framer-motion'
import { useEffect, useRef, useState } from 'react';
import useWindowSize from '../../../assets/hooks/useWindowSize';
import phone from '../../../assets/images/aboutUs/phone3.png'
import phone2 from '../../../assets/images/aboutUs/phone2.png'
import phone3 from '../../../assets/images/aboutUs/phone4.png'
import phone4 from '../../../assets/images/aboutUs/phone1.png'
import phone5 from '../../../assets/images/aboutUs/phone5.png'
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Mousewheel, Pagination } from "swiper/modules";
import "swiper/css";
import ecomm from '../../../assets/images/aboutUs/ecomSteps.png'
import ecommgreen from '../../../assets/images/aboutUs/ecommGreen.png'
import ecommfigure from '../../../assets/images/aboutUs/ecommFigures.png'
import 'swiper/css/pagination';

const autoplayTimer = 8000;
const animationSwiperSpeed = 1000;

function OurStorySteps() {
    const window = useWindowSize()
    const imgRef = useRef(null);
    const imgInView = useInView(imgRef, { once: true, amount: 0.5 });
    const imgControls = useAnimation();

    const [isInView, setIsInView] = useState(false);
    const [swiperInstance, setSwiperInstance] = useState(null);

    // Function to handle visibility change
    const handleVisibilityChange = (inView) => {
        setIsInView(inView);
    };

    // Effect to manage autoplay based on visibility
    useEffect(() => {
        console.log(swiperInstance);
        if (swiperInstance && swiperInstance.autoplay) {
            if (isInView) {
                swiperInstance.autoplay.start();
            } else {
                swiperInstance.autoplay.stop();
            }
        }
    }, [isInView, swiperInstance]);

    useEffect(() => {
        if (imgInView) imgControls.start(["visible", "transform"]);
    }, [imgInView, imgControls]);

    const screenVariants = {
        hidden: (i) => ({
            opacity: i === 2 ? 1 : 0,
            // rotate: 0,
            y: 0,
            x: 0,
        }),
        visible: (i) => ({
            opacity: 1,
            // rotate: i === 0 ? "-7.169deg" : i === 1 ? "2.243deg" : "9.203deg",
            transition: {
                duration: 0.5, // Increased duration for smoother animation
                delay: i === 0 ? 1 : i === 1 ? 0.5 : i === 3 ? 0.5 : i === 5 ? 1 : 0, // Adjusted staggering effect
                ease: "easeOut", // Smooth transition
            },
        }),
        transform: (i) => ({
            x: '0%',
            y: i === 2 ? 0 : i === 1 ? '10%' : i === 0 ? '20%' : i === 3 ? '-10%' : i === 4 ? '-20%' : '',
            transition: {
                duration: 2, // Consistent with the visibility transition for smoothness
                delay: 2, // Adjusted for overall timing
                ease: "easeOut", // Ensures smooth ending
            },
        }),
    };

    // const itemsVariants = {
    //     hidden: { opacity: 0, y: 100 },
    //     visible: (i) => ({
    //         opacity: 1,
    //         y: 0,
    //         transition: {
    //             delay: i * 0.2 + 1.5, // Fine-tuned for a smoother reveal of each item
    //             type: "spring", // Adds a natural dynamic effect
    //             stiffness: 120, // Adjust spring stiffness for desired bounce effect
    //             damping: 10, // Controls the oscillation: lower for more bounce
    //         },
    //     }),
    // };

    const screens = [
        phone4,
        phone2,
        phone,
        phone3,
        phone5,
    ]
    return (
        <motion.div
            className='our-story-steps'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            whileInView={() => handleVisibilityChange(true)}
            onViewportLeave={() => handleVisibilityChange(false)}
        >
            <Swiper
                className="our-story-steps__swiper"
                modules={[Mousewheel, Autoplay, Pagination]}
                speed={animationSwiperSpeed}
                mousewheel={{
                    forceToAxis: true,
                }}

                breakpoints={{
                    900: {
                        slidesPerView: 1,
                        centeredSlides: true,
                        spaceBetween: 0,
                    },
                    0: {
                        slidesPerView: 1.25,
                        centeredSlides: true,
                        spaceBetween: 20,
                    },
                }}
                // autoplay={{ delay: autoplayTimer, stopOnLastSlide: true, disableOnInteraction: false }}
                // Disable initial autoplay, will be controlled by visibility
                autoplay={isInView ? { delay: autoplayTimer, stopOnLastSlide: true } : false}
                onInit={(swiper) => {
                    setSwiperInstance(swiper);
                }}

            >
                <SwiperSlide className="our-story-steps__slide">
                    <div className='our-story-steps_first'>
                        <div className='our-story-steps__text-box'>
                            <div className='our-story-steps__number'>1</div>
                            <p className='our-story-steps__title'>Our story</p>
                            <p className='our-story-steps__subtitle'>
                                Our journey began in 2015 with a vision for revolutionizing e-commerce. We gathered a high-tech, international team of experts to develop a game-changing solution — a one-stop shop that would transform online businesses worldwide. Thus, Sellavi was born.
                            </p>
                            <p className='our-story-steps__subtitle'>
                                Today, we stand tall as a global powerhouse, catering to a thriving community of over 250,000+ satisfied customers globally. With our cutting-edge open SaaS platform, we empower businesses of all sizes to prosper, providing them with the essential tools needed to start, run, and expand effortlessly. But it's not just about business growth — it's about creating unforgettable shopping experiences for your customers, ensuring each interaction is nothing short of exceptional. Join us as we redefine the future of e-commerce together.
                            </p>
                        </div>
                        <div className='our-story-steps_first_img'>
                            {window.width < 960 ?
                                <img alt='' src={house} className='our-story-steps__img'></img>
                                :
                                <motion.img
                                    initial={{ y: '50%' }}
                                    whileInView={{ y: '0%' }}
                                    transition={{ duration: 3 }}
                                    alt='' src={house} className='our-story-steps__img'>
                                </motion.img>
                            }
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="our-story-steps__slide">
                    <div className='our-story-steps_second'>
                        <div className='our-story-steps__text-box'>
                            <div className='our-story-steps__number'>2</div>
                            <p className='our-story-steps__title'>Our mission</p>
                            <p className='our-story-steps__subtitle'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                    <path d="M14 24.5C19.799 24.5 24.5 19.799 24.5 14C24.5 8.20101 19.799 3.5 14 3.5C8.20101 3.5 3.5 8.20101 3.5 14C3.5 19.799 8.20101 24.5 14 24.5Z" fill="#2BBC5D" stroke="#2BBC5D" strokeWidth="1.4734" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M14.6676 16.1863H16.4922V8H14.6676V16.1863ZM11.522 16.1863H13.3466V8H11.522V16.1863ZM9 17.2484L9.71234 16.8944C9.9943 16.7581 10.1868 16.6766 10.3352 16.6766C10.5725 16.6766 10.706 16.8402 10.8991 17.058C11.4034 17.6162 12.3089 18.4612 14 18.4612C15.6911 18.4612 16.5966 17.6168 17.1009 17.058C17.294 16.8402 17.4275 16.6766 17.6648 16.6766C17.8132 16.6766 18.0057 16.7581 18.2877 16.8944L19 17.2484C17.7536 19.346 15.8842 20 14 20C12.1158 20 10.2464 19.346 9 17.2484Z" fill="white" />
                                </svg>  Help businesses of all sizes to sell effortlessly
                            </p>
                            <p className='our-story-steps__subtitle'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                    <path d="M14 24.5C19.799 24.5 24.5 19.799 24.5 14C24.5 8.20101 19.799 3.5 14 3.5C8.20101 3.5 3.5 8.20101 3.5 14C3.5 19.799 8.20101 24.5 14 24.5Z" fill="#2BBC5D" stroke="#2BBC5D" strokeWidth="1.4734" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M14.6676 16.1863H16.4922V8H14.6676V16.1863ZM11.522 16.1863H13.3466V8H11.522V16.1863ZM9 17.2484L9.71234 16.8944C9.9943 16.7581 10.1868 16.6766 10.3352 16.6766C10.5725 16.6766 10.706 16.8402 10.8991 17.058C11.4034 17.6162 12.3089 18.4612 14 18.4612C15.6911 18.4612 16.5966 17.6168 17.1009 17.058C17.294 16.8402 17.4275 16.6766 17.6648 16.6766C17.8132 16.6766 18.0057 16.7581 18.2877 16.8944L19 17.2484C17.7536 19.346 15.8842 20 14 20C12.1158 20 10.2464 19.346 9 17.2484Z" fill="white" />
                                </svg>
                                Provide a comfortable start and fast growth
                            </p>
                            <p className='our-story-steps__subtitle'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                    <path d="M14 24.5C19.799 24.5 24.5 19.799 24.5 14C24.5 8.20101 19.799 3.5 14 3.5C8.20101 3.5 3.5 8.20101 3.5 14C3.5 19.799 8.20101 24.5 14 24.5Z" fill="#2BBC5D" stroke="#2BBC5D" strokeWidth="1.4734" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M14.6676 16.1863H16.4922V8H14.6676V16.1863ZM11.522 16.1863H13.3466V8H11.522V16.1863ZM9 17.2484L9.71234 16.8944C9.9943 16.7581 10.1868 16.6766 10.3352 16.6766C10.5725 16.6766 10.706 16.8402 10.8991 17.058C11.4034 17.6162 12.3089 18.4612 14 18.4612C15.6911 18.4612 16.5966 17.6168 17.1009 17.058C17.294 16.8402 17.4275 16.6766 17.6648 16.6766C17.8132 16.6766 18.0057 16.7581 18.2877 16.8944L19 17.2484C17.7536 19.346 15.8842 20 14 20C12.1158 20 10.2464 19.346 9 17.2484Z" fill="white" />
                                </svg>
                                Give everything a business needs for success, fast and right on the spot
                            </p>
                            <p className='our-story-steps__subtitle'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                    <path d="M14 24.5C19.799 24.5 24.5 19.799 24.5 14C24.5 8.20101 19.799 3.5 14 3.5C8.20101 3.5 3.5 8.20101 3.5 14C3.5 19.799 8.20101 24.5 14 24.5Z" fill="#2BBC5D" stroke="#2BBC5D" strokeWidth="1.4734" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M14.6676 16.1863H16.4922V8H14.6676V16.1863ZM11.522 16.1863H13.3466V8H11.522V16.1863ZM9 17.2484L9.71234 16.8944C9.9943 16.7581 10.1868 16.6766 10.3352 16.6766C10.5725 16.6766 10.706 16.8402 10.8991 17.058C11.4034 17.6162 12.3089 18.4612 14 18.4612C15.6911 18.4612 16.5966 17.6168 17.1009 17.058C17.294 16.8402 17.4275 16.6766 17.6648 16.6766C17.8132 16.6766 18.0057 16.7581 18.2877 16.8944L19 17.2484C17.7536 19.346 15.8842 20 14 20C12.1158 20 10.2464 19.346 9 17.2484Z" fill="white" />
                                </svg>
                                Automate technical processes and optimize online business management
                            </p>
                        </div>

                        <motion.div
                            className=""
                            ref={imgRef}
                            initial="hidden"
                            animate={imgControls}
                        >

                            <ul className="mobile-first__screens-list">
                                {screens.map((img, i) => (
                                    <motion.li
                                        className="mobile-first__screen-item"
                                        key={`mobile-first-screen-${i}`}
                                        custom={i}
                                        variants={screenVariants}
                                    >
                                        <img className="mobile-first__screen-img" src={img} alt="" />
                                    </motion.li>
                                ))}
                            </ul>
                        </motion.div>
                        {/* <motion.div className=''
                            animate={imgControls}>

                            <ul className="mobile-first__screens-list">
                                {screens.map((img, i) => (
                                        <motion.img    
                                        key={`mobile-first-screen-${i}`}
                                        custom={i}
                                        variants={screenVariants}
                                         alt='' src={img.img} className={`our-story-steps__img our-story-steps__img_phone-${i+1}`} >
                                        </motion.img>
                                ))}
                            </ul> */}

                        {/* <motion.img
                                initial={{ opacity: 0.5 }}
                                whileInView={{ opacity: 1 }}
                                transition={{ duration: 1, delay: 0.5 }}
                                alt='' src={phone2} className='our-story-steps__img our-story-steps__img_phone-2'>
                            </motion.img>

                            <motion.img
                                initial={{ opacity: 0.5 }}
                                whileInView={{ opacity: 1 }}
                                transition={{ duration: 2, delay: 0.5 }}
                                alt='' src={phone3} className='our-story-steps__img our-story-steps__img_phone-3'>
                            </motion.img>
                            <motion.img
                                initial={{ opacity: 0 }}
                                whileInView={{ opacity: 1}}
                                transition={{ duration: 2, delay: 1 }}
                                alt='' src={phone4} className='our-story-steps__img our-story-steps__img_phone-4'>
                            </motion.img>
                            <motion.img
                                initial={{ opacity: 0 }}
                                whileInView={{ opacity: 1 }}
                                transition={{ duration: 2, delay: 1 }}
                                alt='' src={phone5} className='our-story-steps__img our-story-steps__img_phone-5'>
                            </motion.img> */}
                        {/* </motion.div> */}
                    </div>
                </SwiperSlide>
                <SwiperSlide className="our-story-steps__slide">
                    <div className='our-story-steps_third'>
                        <div className='our-story-steps__text-box'>
                            <div className='our-story-steps__number'>3</div>
                            <p className='our-story-steps__title'>Best <br></br> E-commerce technology</p>
                        </div>
                        <div className='our-story-steps_third_img'>
                            {window.width < 960 ?
                                <>
                                    <img alt='' src={ecomm} className='our-story-steps__img our-story-steps__img_ecomm'></img>
                                    <img alt='' src={ecommgreen} className='our-story-steps__img our-story-steps__img_ecomm-green'></img>
                                    <img alt='' src={ecommfigure} className='our-story-steps__img our-story-steps__img_ecomm-figure'></img>
                                </>
                                :
                                <>
                                    <motion.img
                                        initial={{ y: '50%' }}
                                        whileInView={{ y: '0%' }}
                                        transition={{ duration: 3 }}
                                        alt='' src={ecomm} className='our-story-steps__img our-story-steps__img_ecomm'>
                                    </motion.img>
                                    <motion.img
                                        initial={{ opacity: 0 }}
                                        whileInView={{ opacity: 1 }}
                                        transition={{ duration: 3 }}
                                        alt='' src={ecommgreen} className='our-story-steps__img our-story-steps__img_ecomm-green'>
                                    </motion.img>
                                    <motion.img
                                        initial={{ opacity: 0 }}
                                        whileInView={{ opacity: 1 }}
                                        transition={{ duration: 3 }}
                                        alt='' src={ecommfigure} className='our-story-steps__img our-story-steps__img_ecomm-figure'>
                                    </motion.img>
                                </>
                            }
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
        </motion.div>
    );
}

export default OurStorySteps