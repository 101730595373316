
import { motion, AnimatePresence } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { FalseTickIcon, TrueTickIcon } from '../../../assets/icons/compareFeatures';
import { DropArrowIcon, PlusPlanIcon, StandartPlanIcon } from '../../../assets/icons/price';
import { PLAN_FEATURES } from '../../../assets/utils/compareFeatures';
import './CompareFeatures.css';
import useWindowSize from '../../../assets/hooks/useWindowSize';

// // Animation variants for the container
// const contentVariants = {
//     hidden: { 
//         opacity: 0, 
//         height: 0,
//         transition: { duration: 0.3 } 
//     },
//     visible: { 
//         opacity: 1, 
//         height: 'auto',
//         transition: { duration: 0.3 }
//     }
// };

// Animation variant for individual items
const itemVariants = (i, total) => ({
    hidden: {
        opacity: 0,
        y: 20,
        transition: { delay: (total - i - 1) * 0.1, duration: 0.1 }
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: { delay: i * 0.1, duration: 0.3 }
    }
});

// // Updated item variants
// const itemVariants = (i, total) => ({
//     hidden: {
//         opacity: 0,
//         y: 20,
//         transition: { delay: i * 0.1, duration: 0.3 }
//     },
//     visible: {
//         opacity: 1,
//         y: 0,
//         transition: { duration: 0.3 }
//     },
//     exit: {
//         opacity: 0,
//         y: 20,
//         transition: { duration: 0.3 }
//     }
// });

// Updated content variants for the container
const contentVariants = (totalItems) => ({
    hidden: {
        opacity: 0,
        height: 0,
        transition: {
            duration: totalItems * 0.03,
            when: "afterChildren"
        }
    },
    visible: {
        opacity: 1,
        height: 'auto',
        transition: { duration: 0.3 }
    }
});

// Fixed header variants
// const fixedHeaderVariants = {
//     hidden: { opacity: 0, y: -50 },
//     visible: { opacity: 1, y: 0 }
// };


function CompareFeatures({ onSignupOpen }) {
    const [selectedItems, setSelectedItems] = useState([PLAN_FEATURES[0]._id]);
    const { width } = useWindowSize()
    const toggleItem = (id) => {
        if (selectedItems.includes(id)) {
            setSelectedItems(selectedItems.filter(item => item !== id));
        } else {
            setSelectedItems([...selectedItems, id]);
        }
    };

    const containerRef = useRef(null);
    const headerRef = useRef(null);
    const fixedHeaderRef = useRef(null);
    const handleScroll = () => {
        const headerRect = headerRef.current.getBoundingClientRect();
        const containerRect = containerRef.current.getBoundingClientRect();
        // const fixedHeaderRect = fixedHeaderRef.current.getBoundingClientRect();

        // Show fixed header when original header is out of view
        if (headerRect.top < (width > 880 ? 34 : 17)) {
            fixedHeaderRef.current.style.display = 'flex';
        } else {
            fixedHeaderRef.current.style.display = 'none';
        }

        // Hide fixed header when scrolling past the compare-features block
        if (containerRect.bottom < 200) {
            fixedHeaderRef.current.style.display = 'none';
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    return (
        <div className='compare-features' ref={containerRef}>
            <h4 className='compare-features__title'>Compare plan features</h4>
            <div className='compare-features__table'>
                <div className='compare-features__table-heading' ref={headerRef}>
                    <div></div>
                    <div className='compare-features__table-heading-content'>
                        <StandartPlanIcon
                            mainClassName={'compare-features__table-heading-icon'}
                        />
                        <button className='compare-features__table-heading-btn' type='button' onClick={onSignupOpen}>
                            <p className='compare-features__table-heading-btn-title'>Create account</p>
                            <span className='compare-features__table-heading-btn-subtitle'>Free 14-days trial</span>
                        </button>
                    </div>
                    <div className='compare-features__table-heading-content'>
                        <PlusPlanIcon
                            mainClassName={'compare-features__table-heading-icon compare-features__table-heading-icon_plus'}
                        />
                        <button className='compare-features__table-heading-btn' type='button' onClick={onSignupOpen}>
                            <p className='compare-features__table-heading-btn-title'>Create account</p>
                            <span className='compare-features__table-heading-btn-subtitle'>Free 14-days trial</span>
                        </button>
                    </div>
                </div>
                <div className='compare-features__table-heading-fixed' ref={fixedHeaderRef}>
                    <div className='compare-features__table-heading-fixed-content'>
                        <div className='compare-features__table-heading'>
                            <div className='compare-features__table-heading-title-content'>
                                <p className='compare-features__table-heading-title'>Compare plan features</p>
                            </div>
                            <div className='compare-features__table-heading-content'>
                                <StandartPlanIcon
                                    mainClassName={'compare-features__table-heading-icon'}
                                />
                                <button className='compare-features__table-heading-btn' type='button' onClick={onSignupOpen}>
                                    <p className='compare-features__table-heading-btn-title'>Create account</p>
                                    <span className='compare-features__table-heading-btn-subtitle'>Free 14-days trial</span>
                                </button>
                            </div>
                            <div className='compare-features__table-heading-content'>
                                <PlusPlanIcon
                                    mainClassName={'compare-features__table-heading-icon compare-features__table-heading-icon_plus'}
                                />
                                <button className='compare-features__table-heading-btn' type='button' onClick={onSignupOpen}>
                                    <p className='compare-features__table-heading-btn-title'>Create account</p>
                                    <span className='compare-features__table-heading-btn-subtitle'>Free 14-days trial</span>
                                </button>
                            </div>
                        </div>
                    </div>


                </div>

                {PLAN_FEATURES.map((item, i) => (
                    <div className={`compare-features__table-item ${i % 2 !== 0 ? 'compare-features__table-item_even' : ''} ${selectedItems.includes(item._id) ? 'compare-features__table-item_selected' : ''}`} key={`compare-features__table-item_${item._id}`}>
                        <button className='compare-features__table-item-head' type='button' onClick={() => toggleItem(item._id)}>
                            {
                                item.icon({
                                    mainClassName: 'compare-features__table-item-head-icon',
                                    fillClassName: 'compare-features__table-item-head-icon-fill'
                                })
                            }
                            <p className='compare-features__table-item-head-title'>{item.title}</p>
                            <DropArrowIcon
                                mainClassName={'compare-features__table-item-head-arrow'}
                                strokeClassName={'compare-features__table-item-head-arrow-stroke'}
                            />
                        </button>
                        <AnimatePresence>
                            {selectedItems.includes(item._id) && (
                                <motion.div
                                    className='compare-features__table-item-inside-items'
                                    variants={contentVariants(item.items.length)}
                                    initial="hidden"
                                    animate="visible"
                                    exit="hidden"
                                    layout
                                >
                                    {item.items.map((item2, i2) => (
                                        <motion.div
                                            className={`compare-features__table-item-inside-item ${i % 2 !== 0 ? i2 % 2 !== 0 ? 'compare-features__table-item-inside-item_even' : '' : i2 % 2 === 0 ? 'compare-features__table-item-inside-item_odd' : ''}`}
                                            key={`compare-features__table-item-inside-item${item._id}-${item2._id}`}
                                            variants={itemVariants(i2, item.items.length)}
                                        >
                                            <div className='compare-features__table-item-inside-item-cell'>
                                                <p className='compare-features__table-item-inside-item-title'>{item2.title}</p>
                                            </div>
                                            <div className='compare-features__table-item-inside-item-answer-cell'>
                                                {item2.standart_value.type === 'text' ?
                                                    <p className='compare-features__table-item-inside-item-answer-text'>{item2.standart_value.value}</p>
                                                    : null}
                                                {item2.standart_value.type === 'icon' ?
                                                    item2.standart_value.value ?
                                                        <TrueTickIcon
                                                            mainClassName={'compare-features__table-item-inside-item-answer-tick'}
                                                            strokeClassName={'compare-features__table-item-inside-item-answer-tick-stroke'}
                                                        />
                                                        :
                                                        // <FalseTickIcon
                                                        //     mainClassName={'compare-features__table-item-inside-item-answer-false-tick'}
                                                        //     strokeClassName={'compare-features__table-item-inside-item-answer-false-tick-stroke'}
                                                        // />
                                                        null
                                                    : null}
                                            </div>
                                            <div className='compare-features__table-item-inside-item-answer-cell'>
                                                {item2.plus_value.type === 'text' ?
                                                    <p className='compare-features__table-item-inside-item-answer-text'>{item2.plus_value.value}</p>
                                                    : null}
                                                {item2.plus_value.type === 'icon' ?
                                                    item2.plus_value.value ?
                                                        <TrueTickIcon
                                                            mainClassName={'compare-features__table-item-inside-item-answer-tick'}
                                                            strokeClassName={'compare-features__table-item-inside-item-answer-tick-stroke'}
                                                        />
                                                        :
                                                        // <FalseTickIcon
                                                        //     mainClassName={'compare-features__table-item-inside-item-answer-false-tick'}
                                                        //     strokeClassName={'compare-features__table-item-inside-item-answer-false-tick-stroke'}
                                                        // />
                                                        null
                                                    : null}
                                            </div>
                                        </motion.div>
                                    ))}

                                </motion.div>
                            )}
                        </AnimatePresence>



                    </div>
                ))}

            </div>
        </div>
    );
}

export default CompareFeatures