import { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { motion, useAnimation, useInView } from "framer-motion";
import "./Steps.css";
import "swiper/css";
import {
  SlideFour,
  SlideThree,
  TemplateFive,
  TemplateFour,
  TemplateOne,
  TemplateSix,
  TemplateThree,
  TemplateTwo,
  TriangleArrow,
} from "../../../assets/icons/stepsTemplateIcons";
import top from "../../../assets/images/steps/smile-top.webp";
import bottom from "../../../assets/images/steps/smile-bottom.webp";
import inside from "../../../assets/images/steps/smile-inside.webp";
import blue from "../../../assets/images/steps/blue-boot.png";
import pink from "../../../assets/images/steps/pink-boot.png";
import yellow from "../../../assets/images/steps/yellow-boot.png";
import green from "../../../assets/images/steps/green-boot.png";
import facebook from "../../../assets/images/steps/facebook-icon.png";
import instagram from "../../../assets/images/steps/instagram-icon.png";
import google from "../../../assets/images/steps/google-icon.png";
import stat from "../../../assets/images/steps/stat-icon.png";
import dollar from "../../../assets/images/steps/dollar-icon.png";
import useWindowSize from "../../../assets/hooks/useWindowSize";

const templatesArr = [
  TemplateOne,
  TemplateTwo,
  TemplateThree,
  TemplateFour,
  TemplateFive,
  TemplateSix,
];
const bootsArr = [blue, pink, yellow, green];
const mediaArr = [
  { icon: facebook, type: "facebook" },
  { icon: instagram, type: "instagram" },
  { icon: google, type: "google" },
];
const analyseArr = [
  { icon: stat, type: "stat" },
  { icon: dollar, type: "big-dollar" },
  { icon: dollar, type: "medium-dollar" },
  { icon: dollar, type: "small-dollar" },
];

const TemplateSlide = ({ templateRef, templateInView, width }) => {
  return (
    <motion.ul
      className="steps__template-list"
      ref={templateRef}
      initial={{ x: 0 }}
      animate={{
        x: templateInView && width <= 1600 ? "-70%" : 0,
      }}
      style={{
        justifyContent: width <= 1600 ? "flex-start" : "center",
      }}
      transition={{ duration: 1.5, delay: 0.4 }}
    >
      {templatesArr.map((template, i) => (
        <li className="steps__template-item" key={`template-${i}`}>
          {template({
            mainClassName: "steps__template-icon",
            bgClassName: "steps__template-bg",
            fillClassName: "steps__template-fill",
            boxBgClassName: "steps__template-box-bg",
          })}
        </li>
      ))}
    </motion.ul>
  );
};

const ProductSlide = ({ bootsRef, bootsControls, itemsVariants }) => {
  return (
    <div className="steps__slide-content">
      <TemplateSix
        mainClassName="steps__template-icon"
        bgClassName="steps__template-bg"
        fillClassName="steps__template-fill"
        boxBgClassName="steps__template-box-bg"
      />
      <motion.ul
        className="steps__boots-list"
        ref={bootsRef}
        initial="hidden"
        animate={bootsControls}
      >
        {bootsArr.map((item, i) => (
          <motion.li
            className="steps__boots-item"
            key={`boot-${i}`}
            custom={i}
            variants={itemsVariants}
          >
            <img className="steps__boot-img" src={item} alt="" />
          </motion.li>
        ))}
      </motion.ul>
    </div>
  );
};

const MediaSlide = ({ mediaRef, mediaControls, itemsVariants }) => {
  return (
    <div className="steps__slide-content">
      <SlideThree
        mainClassName="steps__template-card-icon"
        bgClassName="steps__template-bg"
        fillClassName="steps__template-fill"
        boxBgClassName="steps__template-box-bg"
        lightFillClassName="steps__template-light-fill"
      />
      <img className="steps__slide-img" src={blue} alt="" />
      <motion.ul
        className="steps__slide-list"
        ref={mediaRef}
        initial="hidden"
        animate={mediaControls}
      >
        {mediaArr.map((item, i) => (
          <motion.li
            className={`steps__slide-item steps__slide-item_type_${item.type}`}
            key={`media-${i}`}
            custom={i}
            variants={itemsVariants}
          >
            <img className="steps__media-img" src={item.icon} alt="" />
          </motion.li>
        ))}
      </motion.ul>
    </div>
  );
};

const AnalyseSlide = ({ analyseRef, analyseControls, itemsVariants }) => {
  return (
    <div className="steps__slide-content">
      <SlideFour
        mainClassName="steps__template-analyse-icon"
        bgClassName="steps__template-bg"
        fillClassName="steps__template-fill"
        lightFillClassName="steps__template-light-fill"
      />
      <motion.ul
        className="steps__slide-list"
        ref={analyseRef}
        initial="hidden"
        animate={analyseControls}
      >
        {analyseArr.map((item, i) => (
          <motion.li
            className={`steps__slide-item steps__slide-item_type_${item.type}`}
            key={`media-${i}`}
            custom={i}
            variants={itemsVariants}
          >
            <img className="steps__analyse-img" src={item.icon} alt="" />
          </motion.li>
        ))}
      </motion.ul>
    </div>
  );
};

const slidesArr = [
  {
    title: "Choose a template",
    content: TemplateSlide,
  },
  {
    title: "Upload your products",
    content: ProductSlide,
  },
  {
    title:
      "Sell to everyone, everywhere",
    content: MediaSlide,
  },
  {
    title: "Track and analyze your profits",
    content: AnalyseSlide,
  },
];

const autoplayTimer = 1000;
const animationSwiperSpeed = 1000;

const itemsVariants = {
  hidden: { opacity: 0, y: 100 },
  visible: (i) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: i * 0.3, // Adjust the delay for stagger effect
      type: "spring", // Adds a natural dynamic effect
      stiffness: 100, // Adjust spring stiffness for desired bounce effect
      damping: 13, // Controls the oscillation: lower for more bounce
    },
  }),
};

function Steps() {
  const { width } = useWindowSize();
  const [my_swiper, set_my_swiper] = useState({});
  const [step, setStep] = useState(0);
  const [isControlDisabled, setIsControlDisabled] = useState(false);
  const [isStartAnim, setIsStartAnim] = useState(true);

  const templateRef = useRef(null);
  const templateInView = useInView(templateRef, { once: true });

  const bootsRef = useRef(null);
  const bootsInView = useInView(bootsRef, { once: true });
  const bootsControls = useAnimation();

  const mediaRef = useRef(null);
  const mediaInView = useInView(mediaRef, { once: true });
  const mediaControls = useAnimation();

  const analyseRef = useRef(null);
  const analyseInView = useInView(analyseRef, { once: true });
  const analyseControls = useAnimation();

  useEffect(() => {
    if (bootsInView) {
      bootsControls.start("visible");
    }

    if (mediaInView) {
      mediaControls.start("visible");
    }

    if (analyseInView) {
      analyseControls.start("visible");
    }
  }, [
    bootsInView,
    bootsControls,
    mediaInView,
    mediaControls,
    analyseInView,
    analyseControls,
  ]);

  const swiperRef = useRef(null);
  const swiperInView = useInView(swiperRef, { once: true, amount: 0.5 });

  useEffect(() => {
    if (!my_swiper?.autoplay) return;
    my_swiper.autoplay.stop();
  }, [my_swiper]);

  useEffect(() => {
    if (swiperInView && my_swiper?.autoplay) my_swiper.autoplay.start();
  }, [swiperInView, my_swiper]);

  const onAutoplay = () => {
    setStep((prevVal) => prevVal + 1);
    if (step + 1 === 4) {
      setTimeout(() => setIsStartAnim(false), animationSwiperSpeed);
      my_swiper.autoplay.stop();
    }
  };

  function handleBack() {
    if (my_swiper && step > 1) {
      my_swiper.slidePrev();
      setStep((prevVal) => prevVal - 1);
      setIsControlDisabled(true);
      setTimeout(() => setIsControlDisabled(false), animationSwiperSpeed);
    }
  }

  function handleNext() {
    if (my_swiper && step < 4) {
      my_swiper.slideNext();
      setStep((prevVal) => prevVal + 1);
      setIsControlDisabled(true);
      setTimeout(() => setIsControlDisabled(false), animationSwiperSpeed);
    }
  }

  const smileRef = useRef();
  const smileInView = useInView(smileRef, { once: true, amount: 0.5 });
  const smileControls = useAnimation();

  useEffect(() => {
    if (smileInView) smileControls.start("open");
  }, [smileInView, smileControls]);

  return (
    <motion.div
      className="steps"
      id="steps"
      data-section
      ref={smileRef}
      initial="close"
      animate={smileControls}
    >
      <motion.div
        className="steps__smile-box"
        variants={{
          close: { y: "96.5%" },
          open: { y: "28.5%" },
        }}
        transition={{ duration: 2 }}
      >
        <img className="steps__smile steps__smile_type_top" src={top} alt="" />
        <div className="steps__smile-gradient-box steps__smile-gradient-box_type_top">
          <motion.img
            className="steps__smile-gradient"
            src={inside}
            variants={{
              close: { height: 0 },
              open: { height: "100%" },
            }}
            transition={{ duration: 1 }}
            alt=""
          />
        </div>
      </motion.div>

      <motion.div
        className="steps__container"
        variants={{
          close: { opacity: 0 },
          open: { opacity: 1 },
        }}
        transition={{ duration: 0.2, delay: 2 }}
      >
        <div className="steps__current-step">
          <motion.button
            className="steps__swiper-control steps__swiper-control_back"
            type="button"
            onClick={handleBack}
            key="prev-btn"
            initial={false}
            animate={{ opacity: isStartAnim ? 0 : step <= 1 ? 0.5 : 1 }}
            style={{
              pointerEvents: step <= 1 || isControlDisabled ? "none" : "auto",
            }}
            transition={{ duration: 1 }}
          >
            <TriangleArrow
              mainClassName={"steps__swiper-control-icon"}
              fillClassName={"steps__swiper-control-icon-fill"}
            />
          </motion.button>
          <p className="steps__current-text">Step {step === 0 ? 1 : step}</p>
          <motion.button
            className="steps__swiper-control steps__swiper-control_next"
            type="button"
            onClick={handleNext}
            key="next-btn"
            initial={false}
            animate={{ opacity: isStartAnim ? 0 : step >= 4 ? 0.5 : 1 }}
            style={{
              pointerEvents: step >= 4 || isControlDisabled ? "none" : "auto",
            }}
            transition={{ duration: 1 }}
          >
            <TriangleArrow
              mainClassName={"steps__swiper-control-icon"}
              fillClassName={"steps__swiper-control-icon-fill"}
            />
          </motion.button>
        </div>

        <Swiper
          className="steps__swiper"
          ref={swiperRef}
          slidesPerView={1.41}
          speed={animationSwiperSpeed}
          autoplay={{ delay: autoplayTimer, stopOnLastSlide: true }}
          modules={[Autoplay]}
          direction="vertical"
          preventInteractionOnTransition={true}
          onInit={(ev) => set_my_swiper(ev)}
          onAutoplay={onAutoplay}
        >
          <SwiperSlide className="steps__slide" />
          {slidesArr.map((slide, i) => (
            <SwiperSlide className="steps__slide" key={`slide-${i}`}>
              <motion.div
                className="steps__slide-container"
                key={`slide-container-${i}`}
                initial={{ opacity: 1, scale: 0.7 }}
                animate={{
                  opacity: step <= i + 1 ? 1 : 0,
                  scale: step === i + 1 ? 1 : 0.7,
                }}
                exit={{ opacity: 0, scale: 0.7 }}
                transition={{ duration: 1 }}
              >
                <p
                  className="steps__slide-title"
                  dangerouslySetInnerHTML={{ __html: slide.title }}
                />
                {slide.content({
                  templateRef,
                  templateInView,
                  width,
                  bootsRef,
                  bootsControls,
                  mediaRef,
                  mediaControls,
                  analyseRef,
                  analyseControls,
                  itemsVariants,
                })}
              </motion.div>
            </SwiperSlide>
          ))}
          <SwiperSlide className="steps__slide" />
        </Swiper>
      </motion.div>

      <motion.div
        className="steps__smile-box"
        variants={{
          close: { y: "-96.5%" },
          open: { y: "-29%" },
        }}
        transition={{ duration: 2 }}
      >
        <div className="steps__smile-gradient-box steps__smile-gradient-box_type_bottom">
          <motion.img
            className="steps__smile-gradient"
            src={inside}
            variants={{
              close: { height: 0 },
              open: { height: "100%" },
            }}
            transition={{ duration: 1 }}
            alt=""
          />
        </div>
        <img className="steps__smile" src={bottom} alt="" />
      </motion.div>
    </motion.div>
  );
}

export default Steps;
