import './FaceBookExample.css';
import circle from '../../../../assets/images/createWiithAI/fbCircle.png'
import { motion } from 'framer-motion'
import { FB } from '../../../../assets/icons/icons';

function FaceBookExample({ file }) {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5 }}
            className='facebook-example'>
            <div className='facebook-example__card'>
                <div className='facebook-example__card__user-info-block'>
                    <div className='facebook-example__card__user-info-block'>
                        <FB />
                        <div className=''>
                            <p className='facebook-example__card__user-info-block__name'>Username</p>
                            <p className='facebook-example__card__user-info-block__status'>Advertisement</p>
                        </div>
                    </div>
                    <div className='facebook-example__card__user-info-block__dots'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="4" viewBox="0 0 16 4" fill="none">
                            <circle cx="2" cy="2" r="2" fill="#CFD6DF" />
                            <circle cx="8" cy="2" r="2" fill="#CFD6DF" />
                            <circle cx="14" cy="2" r="2" fill="#CFD6DF" />
                        </svg>
                    </div>
                </div>

                <div className='facebook-example__card__img-box'>
                    <div className='facebook-example__photo-card'>
                        <img src={file} alt='' className='facebook-example__photo-card__img'></img>
                        <div className='facebook-example__photo-card__info-box'>
                            <p className='facebook-example__photo-card__price'>$ 100.90</p>
                            <button className='facebook-example__photo-card__button'>Show now</button>
                        </div>
                    </div>
                    <div>
                        <img src={file} alt='' className='facebook-example__photo-card__img'></img>
                        <p className='facebook-example__photo-card__price'>$ 100.90</p>
                    </div>
                </div>
                <img alt='' className='facebook__circle' src={circle}></img>
            </div>
            <p className='facebook-example__text'>Your Facebook campaign is up & running</p>
        </motion.div>
    );
}

export default FaceBookExample