import { CyberSecurity, CyberSecurityMobile, Ecommerce, EcommerceMobile, Legal, LegalMobile, Logisticts, LogistictsMobile, Marketing, MarketingMobile, Partership, PartnershipMobile, Quality, QualityMobile, ResearchDev, ResearchDevMobile, Support, SupportMobile } from '../../../assets/icons/icons';
import './Departments.css';
import research from '../../../assets/images/aboutUs/research.png'
import quality from '../../../assets/images/aboutUs/quality.png'
import support from '../../../assets/images/aboutUs/support.png'
import ecomm from '../../../assets/images/aboutUs/ecomm.png'
import marketing from '../../../assets/images/aboutUs/marketing.png'
import partner from '../../../assets/images/aboutUs/pertner.png'
import legal from '../../../assets/images/aboutUs/legal.png'
import cyber from '../../../assets/images/aboutUs/cyber.png'
import logict from '../../../assets/images/aboutUs/lodictic.png'
import useWindowSize from '../../../assets/hooks/useWindowSize';
import { useState } from 'react';
import PopupJoin from '../PopupJoin/PopupJoin';

function Departments({ valuesValidity, values, onChange, cleanForm }) {
    const [isOpen, setOpen] = useState(false)
    const CARDS = [
        {
            title: 'Research & Development',
            img: research,
            icon: <ResearchDev />,
            iconMobile: <ResearchDevMobile />
        },
        {
            title: 'Quality Assurance',
            img: quality,
            icon: <Quality />,
            iconMobile: <QualityMobile />
        },
        {
            title: 'Support',
            img: support,
            icon: <Support />,
            iconMobile: <SupportMobile />
        },
        {
            title: 'E-commerce Specialists',
            img: ecomm,
            icon: <Ecommerce />,
            iconMobile: <EcommerceMobile />
        },
        {
            title: 'Marketing & Design',
            img: marketing,
            icon: <Marketing />,
            iconMobile: <MarketingMobile />
        },
        {
            title: 'Partnership',
            img: partner,
            icon: <Partership />,
            iconMobile: <PartnershipMobile />
        },
        {
            title: 'Legal Department',
            img: legal,
            icon: <Legal />,
            iconMobile: <LegalMobile />
        },
        {
            title: 'Cyber Security',
            img: cyber,
            icon: <CyberSecurity />,
            iconMobile: <CyberSecurityMobile />
        },
        {
            title: 'Logistics',
            img: logict,
            icon: <Logisticts />,
            iconMobile: <LogistictsMobile />
        },
    ]
    const window = useWindowSize()

    return (
        <div className='depart'>
              <PopupJoin
                isOpen={isOpen}
                setOpen={setOpen}
                valuesValidity={valuesValidity}
                values={values}
                onChange={onChange}
                cleanForm={cleanForm}
            />
            <p className='depart__title'>
                Departments
            </p>
            <div className='depart__cards-box'>
                {CARDS.map((item, i) => {
                    return (
                        <div className='depart__card' key={item.title}>
                            <div className='depart__card_icon-and-text'>
                                <div className='depart__card__icon'>
                                    {window.width < 960 ? item.iconMobile:item.icon }</div>
                                <p className='depart__card__text'>{item.title}</p>
                            </div>
                            <img alt="" src={item.img} className='depart__card__img'></img>
                        </div>
                    )
                })}
            </div>
            <button onClick={() => setOpen(!isOpen)} className='depart__button'>
                <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
                    <path d="M13.5728 28.7844V0.784424H15.022V28.7844H13.5728ZM28.0649 15.2766H0.0649414V13.8274H28.0649V15.2766Z" fill="white" />
                </svg>
                Join our team
            </button>
        </div>
    );
}

export default Departments