import './SayAboutUs.css';
import pc from '../../../assets/images/sayaboutus/pc.png';
import pc2 from '../../../assets/images/sayaboutus/pc2.png';
import pc3 from '../../../assets/images/sayaboutus/pc3.png';

function SayAboutUs() {
    return (
        <div className='say-about-us'>
          <h3 className='say-about-us__title'>Grow your business with Sellavi</h3>
          <p className='say-about-us__subtitle'>This is what our successful customers say about us</p>
          <div className='say-about-us__box-card'>
            <div className='say-about-us__card say-about-us__card_big '>
                <div className='say-about-us__card__text-box'>
                <p className='say-about-us__card__title'>I raised my sales by 70% thanks to Sellavi</p>
                <p className='say-about-us__card__author'>Klementina Mileva</p>
                </div>
                <img alt='' src={pc} className='say-about-us__card__img'></img>
            </div>
            <div className='say-about-us__card say-about-us__card_small say-about-us__card_small-first'>
                <div className='say-about-us__card__text-box'>
                <p className='say-about-us__card__title'>Everything for smooth business operations is already here</p>
                <p className='say-about-us__card__author'>Altinbas</p>
                </div>
                <img alt='' src={pc2} className='say-about-us__card__img'></img>
            </div>
            <div className='say-about-us__card say-about-us__card_small say-about-us__card_small-second'>
                <div className='say-about-us__card__text-box'>
                <p className='say-about-us__card__title'>Within one week, we were ready to sell</p>
                <p className='say-about-us__card__author'>DB Prive</p>
                </div>
                <img alt='' src={pc3} className='say-about-us__card__img'></img>
            </div>
          </div>
        </div>
    );
}

export default SayAboutUs