import { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { ContentNavArrow } from "../../../../assets/icons/icons";
import { HEADING_BLOCK } from "../../../../assets/utils/constants";
import "./ContentNav.css";

// Animation variant for individual items
const itemVariants = (i, total) => ({
  hidden: {
    opacity: 0,
    y: 20,
    transition: { delay: (total - i - 1) * 0.15, duration: 0.15 },
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: { delay: i * 0.2, duration: 0.3 },
  },
});

// Updated content variants for the container
const contentVariants = (totalItems) => ({
  hidden: {
    opacity: 0,
    height: 0,
    transition: {
      duration: totalItems * 0.03,
      when: "afterChildren",
    },
  },
  visible: {
    opacity: 1,
    height: "auto",
    transition: { duration: 0.3 },
  },
});

function ContentNav({ article }) {
  const [isOpen, setIsOpen] = useState(true);

  const navList = article.reduce((prevVal, item, i) => {
    if (item.type === HEADING_BLOCK && item.level === 1) {
      return [
        ...prevVal,
        { value: item.children[0].text, id: `${item.type}-${item.level}${i}` },
      ];
    }

    if (item.type === HEADING_BLOCK && item.level === 2 && prevVal.length > 0) {
      const lastItem = prevVal[prevVal.length - 1];

      if (!lastItem.submenu) {
        lastItem.submenu = [
          {
            value: item.children[0].text,
            id: `${item.type}-${item.level}${i}`,
          },
        ];
      } else {
        lastItem.submenu.push({
          value: item.children[0].text,
          id: `${item.type}-${item.level}${i}`,
        });
      }

      return prevVal;
    }

    return prevVal;
  }, []);

  useEffect(() => {
    function smoothScroll(e) {
      e.preventDefault();

      document.querySelector(this.getAttribute("href")).scrollIntoView({
        behavior: "smooth",
      });
    }

    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener("click", smoothScroll);
      return () => {
        anchor.removeEventListener("mousedown", smoothScroll);
      };
    });
  });

  function toggleMenu() {
    setIsOpen((prevVal) => !prevVal);
  }

  return (
    <nav className="content-nav">
      <div className="content-nav__title-box">
        <p className="content-nav__title">Content</p>
        <motion.button
          className="content-nav__toggle-btn"
          type="button"
          initial={{ rotate: 0 }}
          animate={{ rotate: isOpen ? 0 : 180 }}
          transition={{ duration: 0.2, ease: "easeInOut" }}
          onClick={toggleMenu}
        >
          <ContentNavArrow
            mainClassName="content-nav__arrow-icon"
            fillClassName="content-nav__arrow-icon-fill"
          />
        </motion.button>
      </div>

      <AnimatePresence>
        {navList.length > 0 && isOpen && (
          <motion.ol
            className="content-nav__list"
            variants={contentVariants(navList.length)}
            initial="hidden"
            animate="visible"
            exit="hidden"
            layout
            key={isOpen}
          >
            {navList.map((title, i) => (
              <motion.li
                className="content-nav__list-item"
                variants={itemVariants(i, navList.length)}
                key={title.id}
              >
                <p className="content-nav__text">
                  {i + 1}.{" "}
                  <a className="link" href={`#${title.id}`}>
                    {title.value}
                  </a>
                </p>

                {title.submenu ? (
                  <ol className="content-nav__list content-nav__list_offset">
                    {title.submenu.map((subtitle, idx) => (
                      <li className="content-nav__list-item" key={subtitle.id}>
                        <p className="content-nav__text">
                          {i + 1}.{idx + 1}{" "}
                          <a className="link" href={`#${subtitle.id}`}>
                            {subtitle.value}
                          </a>
                        </p>
                      </li>
                    ))}
                  </ol>
                ) : null}
              </motion.li>
            ))}
          </motion.ol>
        )}
      </AnimatePresence>
    </nav>
  );
}

export default ContentNav;
