import bg from "../../../assets/images/partners/heading/bg.webp";
import people from "../../../assets/images/partners/heading/people-img.webp";
import notebook from "../../../assets/images/partners/heading/notebook-img.webp";
import sales from "../../../assets/images/partners/heading/checkmark-icon.png";
import orders from "../../../assets/images/partners/heading/orders-icon.png";
import carts from "../../../assets/images/partners/heading/carts-icon.png";
import items from "../../../assets/images/partners/heading/items-icon.png";
import "./PartnersHeading.css";

function PartnersHeading() {
  return (
    <div className="partners-heading">
      <h1 className="partners-heading__title">Sellavi's affiliate program</h1>
      <p className="partners-heading__subtitle">
        Get to know our affiliate program and become
        Sellavi's&nbsp;business&nbsp;partner!
      </p>

      <div className="partners-heading__imgs-block">
        <ul className="partners-heading__info-list">
          <li className="partners-heading__info-item">
            <div className="partners-heading__card partners-heading__card_type_green">
              <p className="partners-heading__card-numbers">885 356</p>
              <p className="partners-heading__card-text">Total sales</p>
              <div className="partners-heading__card-bg">
                <img
                  className="partners-heading__card-icon"
                  src={sales}
                  alt=""
                />
                <div className="partners-heading__card-gradient" />
              </div>
            </div>
            <div className="partners-heading__card partners-heading__card_type_blue">
              <p className="partners-heading__card-numbers">290</p>
              <p className="partners-heading__card-text">Abandoned carts</p>
              <div className="partners-heading__card-bg">
                <img
                  className="partners-heading__card-icon"
                  src={carts}
                  alt=""
                />
                <div className="partners-heading__card-gradient" />
              </div>
            </div>
          </li>
          <li className="partners-heading__info-item">
            <div className="partners-heading__card partners-heading__card_type_yellow">
              <p className="partners-heading__card-numbers">3 688</p>
              <p className="partners-heading__card-text">Number of orders</p>
              <div className="partners-heading__card-bg">
                <img
                  className="partners-heading__card-icon"
                  src={orders}
                  alt=""
                />
                <div className="partners-heading__card-gradient" />
              </div>
            </div>
            <div className="partners-heading__card partners-heading__card_type_red">
              <p className="partners-heading__card-numbers">1 160</p>
              <p className="partners-heading__card-text">Abandoned items</p>
              <div className="partners-heading__card-bg">
                <img
                  className="partners-heading__card-icon"
                  src={items}
                  alt=""
                />
                <div className="partners-heading__card-gradient" />
              </div>
            </div>
          </li>
        </ul>

        <img className="partners-heading__people-img" src={people} alt="" />
        <img className="partners-heading__notebook-img" src={notebook} alt="" />
      </div>
      <img className="partners-heading__bg" src={bg} alt="" />
    </div>
  );
}

export default PartnersHeading;
