import { Link, NavLink, useLocation } from 'react-router-dom';
import './Header.css';
import { LogoIcon, MobileMenuIcon, SunIcon } from '../../assets/icons/icons';
import { HEADER_LINKS, THEME_DARK_TYPE, THEME_LIGHT_TYPE } from '../../assets/utils/constants';
import { useContext, useState } from 'react';
import { ThemeContext } from '../../assets/contexts/themeContext';
import useScrollDirection from '../../assets/hooks/useScrollDirection';
import HeaderPopup from './HeaderPopup/HeaderPopup';
import useWindowSize from '../../assets/hooks/useWindowSize';

function Header() {
    const { theme, setTheme } = useContext(ThemeContext);
    const scrollDirection = useScrollDirection();
    const [isHeaderPopupOpen, setHeaderPopupOpen] = useState(false)
    const { pathname } = useLocation()
    const { width } = useWindowSize()

    return (
        <header className={`header ${scrollDirection === "down" ? "header_hide" : "header_show"}`} style={{ '--logo-position': width > 1920 ? `${(width - 1920) / 2 + 80}px` : '80px' }}>
            <div className='header__content'>
                <Link className='header__logo-box' to={'/'}>
                    <LogoIcon
                        mainClassName={`header__logo ${pathname === '/' && width > 1200 ? 'header__logo_fixed' : ''}`}
                    />
                </Link>
                <div className='header__links'>
                    {HEADER_LINKS.map((link, i) => (
                        <NavLink
                            key={`header__link_${i}`}
                            to={link.path}
                            className={({ isActive }) =>
                                `header__link ${isActive ? "header__link_active" : ""
                                } ${link.disabled ? "header__link_disabled" : ""}`
                            }
                        >
                            {link.name}
                        </NavLink>
                    ))}

                </div>
                <div className='header__controls'>
                    <a className='header__login' href='https://uae.sellavi.com/register' target='_blank' rel='noreferrer'>Sign in</a>
                    <button className='header__control' onClick={() => {
                        setTheme(theme === THEME_LIGHT_TYPE ? THEME_DARK_TYPE : THEME_LIGHT_TYPE)
                    }}>
                        <SunIcon
                            mainClassName={'header__control-icon'}
                            fillClassName={'header__control-icon-fill'}
                        />
                    </button>
                    <button className='header__control-mobile-menu' type='button' onClick={() => {
                        setHeaderPopupOpen(prevValue => !prevValue)
                    }}>
                        <MobileMenuIcon
                            mainClassName={'header__control-mobile-menu-icon'}
                            strokeClassName={'header__control-mobile-menu-icon-stroke'}
                        />
                    </button>
                </div>
            </div>
            <HeaderPopup
                isHeaderPopupOpen={isHeaderPopupOpen}
                setHeaderPopupOpen={setHeaderPopupOpen}
            />
        </header>
    );
}

export default Header