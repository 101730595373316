import { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Mousewheel, Navigation } from "swiper/modules";
import ReviewSlide from "./ReviewSlide/ReviewSlide";
// import slide_1 from '../../../assets/images/reviews/bogoleva.png'
// import slide_1_mobile from '../../../assets/images/reviews/bogoleva-mobile.png'

import author_1 from '../../../assets/images/reviews/author-1.png'
import author_2 from '../../../assets/images/reviews/author-2.png'
import author_3 from '../../../assets/images/reviews/author-3.png'
import author_4 from '../../../assets/images/reviews/author-4.png'
import author_5 from '../../../assets/images/reviews/author-5.png'
import author_6 from '../../../assets/images/reviews/author-6.png'
import author_7 from '../../../assets/images/reviews/author-7.png'
import author_8 from '../../../assets/images/reviews/author-8.png'
import author_9 from '../../../assets/images/reviews/author-9.png'
import author_10 from '../../../assets/images/reviews/author-10.png'

import author_logo_1_black from '../../../assets/images/reviews/author-logo-1-black.png'
import author_logo_1_white from '../../../assets/images/reviews/author-logo-1-white.png'
import author_logo_2_black from '../../../assets/images/reviews/author-logo-2-black.png'
import author_logo_2_white from '../../../assets/images/reviews/author-logo-2-white.png'
import author_logo_3_black from '../../../assets/images/reviews/author-logo-3-black.png'
import author_logo_3_white from '../../../assets/images/reviews/author-logo-3-white.png'
import author_logo_4_black from '../../../assets/images/reviews/author-logo-4-black.png'
import author_logo_4_white from '../../../assets/images/reviews/author-logo-4-white.png'
import author_logo_5_black from '../../../assets/images/reviews/author-logo-5-black.png'
import author_logo_5_white from '../../../assets/images/reviews/author-logo-5-white.png'
import author_logo_6_black from '../../../assets/images/reviews/author-logo-6-black.png'
import author_logo_6_white from '../../../assets/images/reviews/author-logo-6-white.png'
import author_logo_7_black from '../../../assets/images/reviews/author-logo-7-black.png'
import author_logo_7_white from '../../../assets/images/reviews/author-logo-7-white.png'
import author_logo_8_black from '../../../assets/images/reviews/author-logo-8-black.png'
import author_logo_8_white from '../../../assets/images/reviews/author-logo-8-white.png'
import author_logo_9_black from '../../../assets/images/reviews/author-logo-9-black.png'
import author_logo_9_white from '../../../assets/images/reviews/author-logo-9-white.png'
import author_logo_10_black from '../../../assets/images/reviews/author-logo-10-black.png'
import author_logo_10_white from '../../../assets/images/reviews/author-logo-10-white.png'

import card_pc_1 from '../../../assets/images/reviews/cards/pc1.webp'
import card_pc_2 from '../../../assets/images/reviews/cards/pc2.webp'
import card_pc_3 from '../../../assets/images/reviews/cards/pc3.webp'
import card_pc_4 from '../../../assets/images/reviews/cards/pc4.webp'
import card_pc_5 from '../../../assets/images/reviews/cards/pc5.webp'
import card_pc_6 from '../../../assets/images/reviews/cards/pc6.webp'
import card_pc_7 from '../../../assets/images/reviews/cards/pc7.webp'
import card_pc_8 from '../../../assets/images/reviews/cards/pc8.webp'
import card_pc_9 from '../../../assets/images/reviews/cards/pc9.webp'
import card_pc_10 from '../../../assets/images/reviews/cards/pc10.webp'

import card_mobile_1 from '../../../assets/images/reviews/cards/phone1.webp'
import card_mobile_2 from '../../../assets/images/reviews/cards/phone2.webp'
import card_mobile_3 from '../../../assets/images/reviews/cards/phone3.webp'
import card_mobile_4 from '../../../assets/images/reviews/cards/phone4.webp'
import card_mobile_5 from '../../../assets/images/reviews/cards/phone5.webp'
import card_mobile_6 from '../../../assets/images/reviews/cards/phone6.webp'
import card_mobile_7 from '../../../assets/images/reviews/cards/phone7.webp'
import card_mobile_8 from '../../../assets/images/reviews/cards/phone8.webp'
import card_mobile_9 from '../../../assets/images/reviews/cards/phone9.webp'
import card_mobile_10 from '../../../assets/images/reviews/cards/phone10.webp'

import "swiper/css";
import "swiper/css/pagination";
import './Reviews.css';

const REVIEWS = [
    {
        author: {
            name: 'Anna',
            img: author_1,
            site: 'maison.bg',
            logo: author_logo_1_black,
            logo_theme_white: author_logo_1_white,
        },
        description: `We opted for Sellavi due to the platform's reliability and security. The store maintenance used to be our biggest challenge, but Sellavi has made the process smooth and hassle-free. The support we received from the team while setting up the online store left a lasting impression. The Sellavi team's remarkable patience and commitment truly stand out, and the outcome reflects the exceptional quality of their services.`,
        img_pc: card_pc_1,
        img_mobile: card_mobile_1,

    },
    {
        author: {
            name: 'Isabelle',
            img: author_2,
            site: 'roue.bg',
            logo: author_logo_2_black,
            logo_theme_white: author_logo_2_white
        },
        description: `I chose Sellavi for my online store because they truly listened to my ideas.

        I submitted a request, spoke with a manager about my needs, paid for the Sellavi Plus plan, and within a couple of weeks, I had a ready-made website that perfectly reflected my brand. The visual design and the functionality, especially for my customizable products, were top-notch. I’ve been a client for some time now and have no plans to switch.`,
        img_pc: card_pc_2,
        img_mobile: card_mobile_2,

    },
    {
        author: {
            name: 'Nia Nikolova',
            img: author_3,
            site: 'houseoflememe.com',
            logo: author_logo_3_black,
            logo_theme_white: author_logo_3_white
        },
        description: `The interaction with the Sellavi team is effortless and enjoyable; they provide essential instructions for the workflow and help us bring our desires and vision to life. Working on the platform is incredibly easy due to the user-friendly interface. With minimal effort, we accomplish remarkable outcomes.`,
        img_pc: card_pc_3,
        img_mobile: card_mobile_3,
    },
    {
        author: {
            name: 'Klementina Mileva',
            img: author_4,
            site: 'trendyconfession.com',
            logo: author_logo_4_black,
            logo_theme_white: author_logo_4_white
        },
        description: `Choosing Sellavi to create my online store has been an absolute breeze. The platform's user-friendly interface made the entire process smooth and fast. Not only that, but the end result exceeded my expectations – the store looks amazing! My online shop received amazing reviews from customers right after launching!`,
        img_pc: card_pc_4,
        img_mobile: card_mobile_4,
    },
    {
        author: {
            name: 'Tоdor Angelov',
            img: author_5,
            site: 'invoke.bg',
            logo: author_logo_5_black,
            logo_theme_white: author_logo_5_white
        },
        description: `I chose Sellavi to power my online store because it delivers everything I envisioned in a website-building platform: a diverse range of themes tailored for different store types, sleek and clean design, seamless integration with Google Analytics, automatic listings on Facebook and Instagram catalogs, and reliable sales analysis tools. It was the best decision - my store is thriving like never before!`,
        img_pc: card_pc_5,
        img_mobile: card_mobile_5,
    },
    {
        author: {
            name: 'Borislava Georgieva',
            img: author_6,
            site: 'bolgeva.com',
            logo: author_logo_6_black,
            logo_theme_white: author_logo_6_white
        },
        description: `I came across Sellavi when I was on the hunt for the perfect platform to build my brand's online store. What stood out to me was the ease and user-friendliness the platform offered, as well as the team's quick response and dedication. I'm absolutely delighted with the final product and have received a ton of compliments from clients and friends.`,
        img_pc: card_pc_6,
        img_mobile: card_mobile_6,
    },
    {
        author: {
            name: 'Altinbas',
            img: author_7,
            site: 'altinbas.bg',
            logo: author_logo_7_black,
            logo_theme_white: author_logo_7_white
        },
        description: `The Sellavi platform operates flawlessly! I'm particularly fond of the product section; it's user-friendly, and the outcome on the website looks stunning. Our customers effortlessly pick their desired items and proceed to place their orders directly, reducing the need for inquiries through contact forms. Honestly, collaborating with Sellavi is the best you can do for your business.`,
        img_pc: card_pc_7,
        img_mobile: card_mobile_7,
    },
    {
        author: {
            name: 'Miglena',
            img: author_8,
            site: 'bambinibg.com',
            logo: author_logo_8_black,
            logo_theme_white: author_logo_8_white
        },
        description: `I created my online store using the Sellavi platform, and I confidently state that I'm absolutely delighted with everything it offers: the communication with the team, the diverse array of features, and the user-friendly nature of the platform itself. I wholeheartedly recommend this platform to anyone seeking a straightforward and effective solution for establishing an online shop.`,
        img_pc: card_pc_8,
        img_mobile: card_mobile_8,
    },
    {
        author: {
            name: 'Kremena Ivanova-Milusheva',
            img: author_9,
            site: 'scrapbooky.net',
            logo: author_logo_9_black,
            logo_theme_white: author_logo_9_white
        },
        description: `Discovering Sellavi while searching for a platform for our online store has been an absolute happiness! I had experimented with various options before and was almost ready to give up, but then I found this platform. Sellavi responded positively to all my queries and requests, displaying patience and empathy. Each team member who contributed to our store possesses a distinct persona and a customer-centric approach that ensures every client feels valued!`,
        img_pc: card_pc_9,
        img_mobile: card_mobile_9,
    },
    {
        author: {
            name: 'Gabriella',
            img: author_10,
            site: 'gabrielleshop.eu',
            logo: author_logo_10_black,
            logo_theme_white: author_logo_10_white
        },
        description: `What truly stood out to me about the Sellavi platform is the wide variety of functionalities and extensions. My customers are happier than ever: their feedback is extremely positive, and they find the shop so easy to work with. This is what matters to me the most!`,
        img_pc: card_pc_10,
        img_mobile: card_mobile_10,
    },
]

function Reviews({ theme }) {
    // const [my_swiper, set_my_swiper] = useState({});

      // Preload images
    useEffect(() => {
        const imagesToPreload = [card_pc_1, card_pc_2, card_pc_3, card_pc_4, card_pc_5, card_pc_6, card_pc_7, card_pc_8, card_pc_9, card_pc_10, card_mobile_1, card_mobile_2, card_mobile_3, card_mobile_4, card_mobile_5, card_mobile_6, card_mobile_7, card_mobile_8, card_mobile_9, card_mobile_10];
        imagesToPreload.forEach((imageSrc) => {
            const img = new Image();
            img.src = imageSrc;
        });
    }, []);

    return (
        <div className='reviews' id='reviews' data-section>
            <div className='reviews__content'>
                <Swiper
                    slidesPerView={1}
                    loop={true}
                    spaceBetween={16}
                    pagination={{
                        clickable: true,
                    }}
                    mousewheel={{
                        forceToAxis: true,
                    }}
                    modules={[Mousewheel, Navigation, Pagination]}
                    preventInteractionOnTransition={true}
                    className="reviews__slides reviews__slides__pagination"
                    // onInit={(ev) => {
                    //     set_my_swiper(ev)
                    // }}
                >
                    {
                        REVIEWS.map((slide, i) => (
                            <SwiperSlide className="reviews__slide" key={`review-slide-${i}`}>
                                <ReviewSlide index={i + 1} {...{ slide, theme }} />
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            </div>
        </div>
    );
}

export default Reviews