import { useEffect, useRef } from "react";
import { useAnimation, useInView, motion } from "framer-motion";
import bg from "../../../assets/images/mobile-first/bg.webp";
import screen1 from "../../../assets/images/mobile-first/screen1.webp";
import screen2 from "../../../assets/images/mobile-first/screen2.webp";
import screen3 from "../../../assets/images/mobile-first/screen3.webp";
import add from "../../../assets/images/mobile-first/add-cart-icon.png";
import cart from "../../../assets/images/mobile-first/cart-icon.png";
import chat from "../../../assets/images/mobile-first/chat-icon.png";
import mobile from "../../../assets/images/mobile-first/mobile-icon.png";
import security from "../../../assets/images/mobile-first/security-icon.png";
import settings from "../../../assets/images/mobile-first/settings-icon.png";
import wallet from "../../../assets/images/mobile-first/wallet-icon.png";
import "./MobileFirst.css";

const screens = [screen1, screen2, screen3];
const icons = [cart, wallet, mobile, security, add, settings, chat];

const screenVariants = {
  hidden: (i) => ({
    opacity: i === 0 ? 1 : 0,
    rotate: 0,
    x: i === 0 ? "35%" : i === 2 ? "-46%" : 0,
    y: i === 0 ? "-5%" : i === 2 ? "-6%" : 0,
  }),
  visible: (i) => ({
    opacity: 1,
    rotate: i === 0 ? "-7.169deg" : i === 1 ? "2.243deg" : "9.203deg",
    transition: {
      duration: 0.25, // Increased duration for smoother animation
      delay: i * 0.1, // Adjusted staggering effect
      ease: "easeOut", // Smooth transition
    },
  }),
  transform: {
    x: 0,
    y: 0,
    transition: {
      duration: 0.25, // Consistent with the visibility transition for smoothness
      delay: 0.45, // Adjusted for overall timing
      ease: "easeOut", // Ensures smooth ending
    },
  },
};

const itemsVariants = {
  hidden: { opacity: 0, y: 100 },
  visible: (i) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: i * 0.1 + 0.75, // Fine-tuned for a smoother reveal of each item
      type: "spring", // Adds a natural dynamic effect
      stiffness: 120, // Adjust spring stiffness for desired bounce effect
      damping: 10, // Controls the oscillation: lower for more bounce
    },
  }),
};

function MobileFirst() {
  const imgRef = useRef(null);
  const imgInView = useInView(imgRef, { once: true, amount: 0.5 });
  const imgControls = useAnimation();

  useEffect(() => {
    if (imgInView) imgControls.start(["visible", "transform"]);
  }, [imgInView, imgControls]);

  return (
    <div className="mobile-first" id="features" data-section>
      <div className="mobile-first__content">
        <h3 className="mobile-first__title">Mobile-first approach</h3>
        <p className="mobile-first__subtitle">
          Sellavi is built for mobile commerce. We provide free advanced mobile
          optimization, ensuring your store functions flawlessly and looks
          stunning across all portable devices.
        </p>
        <motion.div
          className="mobile-first__img-box"
          ref={imgRef}
          initial="hidden"
          animate={imgControls}
        >
          <img className="mobile-first__img-bg" src={bg} alt="" />
          <ul className="mobile-first__screens-list">
            {screens.map((img, i) => (
              <motion.li
                className="mobile-first__screen-item"
                key={`mobile-first-screen-${i}`}
                custom={i}
                variants={screenVariants}
              >
                <img className="mobile-first__screen-img" src={img} alt="" />
              </motion.li>
            ))}
          </ul>
          <ul className="mobile-first__icons-list">
            {icons.map((icon, i) => (
              <motion.li
                className="mobile-first__icon-item"
                key={`mobile-first-icon-${i}`}
                custom={i}
                variants={itemsVariants}
              >
                <img className="mobile-first__icon" src={icon} alt="" />
              </motion.li>
            ))}
          </ul>
        </motion.div>
      </div>
    </div>
  );
}

export default MobileFirst;
