import Reviews from '../Main/Reviews/Reviews';
import Bennefits from './Bennefits/Bennefits';
import ChoosePlan from './ChoosePlan/ChoosePlan';
import CompareFeatures from './CompareFeatures/CompareFeatures';
import FAQ from './FAQ/FAQ';
import './Price.css';
import Suitable from './Suitable/Suitable';

function Price({ onSignupOpen, theme }) {
    return (
        <div className='price'>
            <ChoosePlan {...{ onSignupOpen }} />
            <CompareFeatures {...{ onSignupOpen }} />
            <FAQ />
            <Reviews theme={theme} />
            <Bennefits {...{ onSignupOpen }} />
            <Suitable />
        </div>
    );
}

export default Price