import './HeaderBannerAboutUs.css';
import phones from '../../../assets/images/aboutUs/phones.png'
import phonesMobile from '../../../assets/images/aboutUs/phones-mobile.png'
import { motion } from 'framer-motion'
import useWindowSize from '../../../assets/hooks/useWindowSize';

function HeaderBannerAboutUs() {
  const window = useWindowSize()
  return (
    <motion.div
      className='header-banner-about-us'>
      <p className='header-banner-about-us__title'>
        <span className='header-banner-about-us__title_green'>250 000+</span>
        online stores thrive with Sellavi</p>
      <p className='header-banner-about-us__subtitle'>Welcome to Sellavi, a client-oriented global e-commerce platform. We are here to liberate any kind of business from the never-ending hustle with online stores. We prioritize our merchants, ensuring a seamless and trouble-free experience in the world of e-commerce. Say goodbye to the constant challenges, and let us simplify and optimize your online business journey. <br/>Embrace the ease, embrace Sellavi.</p>
      <motion.img
        initial={{ x: '100%' }}
        animate={{ x: '0%' }}
        transition={{ duration: 3 }}
        alt='' src={window.width>=800?phones: phonesMobile} className='header-banner-about-us__img'></motion.img>
    </motion.div>
  );
}

export default HeaderBannerAboutUs