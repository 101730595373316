import { motion, useAnimation, useInView } from 'framer-motion';
import React, { useEffect, useRef } from 'react';


import './Store.css';
import item_1 from '../../../assets/images/store/item-1.png'
import item_2 from '../../../assets/images/store/item-2.png'
import item_3 from '../../../assets/images/store/item-3.png'
import item_4 from '../../../assets/images/store/item-4.png'
import item_5 from '../../../assets/images/store/item-5.png'
import item_6 from '../../../assets/images/store/item-6.png'
import item_7 from '../../../assets/images/store/item-7.png'
import item_8 from '../../../assets/images/store/item-8.png'
import item_9 from '../../../assets/images/store/item-9.png'
import item_10 from '../../../assets/images/store/item-10.png'
import item_11 from '../../../assets/images/store/item-11.png'
import item_12 from '../../../assets/images/store/item-12.png'
import item_13 from '../../../assets/images/store/item-13.png'
import item_14 from '../../../assets/images/store/item-14.png'
import item_15 from '../../../assets/images/store/item-15.png'
import item_16 from '../../../assets/images/store/item-16.png'
import item_17 from '../../../assets/images/store/item-17.png'
import item_18 from '../../../assets/images/store/item-18.png'
import item_19 from '../../../assets/images/store/item-19.png'
import item_20 from '../../../assets/images/store/item-20.png'
import item_21 from '../../../assets/images/store/item-21.png'
import item_22 from '../../../assets/images/store/item-22.png'
import item_23 from '../../../assets/images/store/item-23.png'
import item_24 from '../../../assets/images/store/item-24.png'
import item_25 from '../../../assets/images/store/item-25.png'
import item_26 from '../../../assets/images/store/item-26.png'
import item_27 from '../../../assets/images/store/item-27.png'
import item_28 from '../../../assets/images/store/item-28.png'
import item_29 from '../../../assets/images/store/item-29.png'
import item_30 from '../../../assets/images/store/item-30.png'
import item_31 from '../../../assets/images/store/item-31.png'
import item_32 from '../../../assets/images/store/item-32.png'
import item_33 from '../../../assets/images/store/item-33.png'
import item_34 from '../../../assets/images/store/item-34.png'
import item_35 from '../../../assets/images/store/item-35.png'
import item_36 from '../../../assets/images/store/item-36.png'
import item_37 from '../../../assets/images/store/item-37.png'
import item_38 from '../../../assets/images/store/item-38.png'
import item_39 from '../../../assets/images/store/item-39.png'
import item_40 from '../../../assets/images/store/item-40.png'
import item_41 from '../../../assets/images/store/item-41.png'
import item_42 from '../../../assets/images/store/item-42.png'

const ITEMS = [
    {
        title: 'Unlimited Hosting',
        img: item_1,
    },
    {
        title: 'Customizable Templates',
        img: item_2,
    },
    {
        title: 'Advanced CRM',
        img: item_3,
    },
    {
        title: 'Free SSL',
        img: item_4,
    },
    {
        title: 'Built-in SEO',
        img: item_5,
    },
    {
        title: 'Multiple Currencies',
        img: item_6,
    },
    {
        title: 'Multiple Languages',
        img: item_7,
    },
    {
        title: 'Multi-User Admin Panel',
        img: item_8,
    },
    {
        title: 'Integrated Online Payments',
        img: item_9,
    },
    {
        title: 'Apple Pay & Google Pay',
        img: item_10,
    },
    {
        title: 'Invoice Management',
        img: item_11,
    },
    {
        title: 'Advanced Reports',
        img: item_12,
    },
    {
        title: 'Advanced Promotion System',
        img: item_13,
    },
    {
        title: 'SMS Notifications',
        img: item_14,
    },
    {
        title: 'Facebook Pixel API',
        img: item_15,
    },
    {
        title: 'Google Analytics API',
        img: item_16,
    },
    {
        title: 'Abandoned Cart Management',
        img: item_17,
    },
    {
        title: 'Customer Groups',
        img: item_18,
    },
    {
        title: 'Subscription Management',
        img: item_19,
    },
    {
        title: 'Booking',
        img: item_20,
    },
    {
        title: 'Product Reviews & Rating',
        img: item_21,
    },
    {
        title: 'Fulfillment',
        img: item_22,
    },
    {
        title: 'Coupon Generator',
        img: item_23,
    },
    {
        title: 'Blog',
        img: item_24,
    },
    {
        title: 'Advanced Delivery Management',
        img: item_25,
    },
    {
        title: 'JS and CSS Editors',
        img: item_26,
    },
    {
        title: 'Digital Products',
        img: item_27,
    },
    {
        title: 'Checkout Customization',
        img: item_28,
    },
    {
        title: 'Rich API with Webhooks',
        img: item_29,
    },
    {
        title: 'Analytics Dashboard',
        img: item_30,
    },
    {
        title: 'Modular Page Builder',
        img: item_31,
    },
    {
        title: 'Google ADS Connection',
        img: item_32,
    },
    {
        title: 'Exclusive Offers from Partners',
        img: item_33,
    },
    {
        title: 'Instagram Shopping & Facebook Catalog',
        img: item_34,
    },
    {
        title: 'Google Shopping',
        img: item_35,
    },
    {
        title: 'TikTok Shop',
        img: item_36,
    },
    {
        title: 'Complementary Products',
        img: item_37,
    },
    {
        title: 'Sales Pages',
        img: item_38,
    },
    {
        title: 'Video and Image Banners',
        img: item_39,
    },
    {
        title: 'Product Filters & Sorting',
        img: item_40,
    },
    {
        title: 'Google Rich Snippets',
        img: item_41,
    },
    {
        title: 'Email Marketing',
        img: item_42,
    },
]

function Store({ onSignupOpen }) {
    const containerVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                staggerChildren: 0.05,
            },
        },
    };

    const itemVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0 },
    };

    const controls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    useEffect(() => {
        if (isInView) {
            controls.start('visible');
        }
    }, [controls, isInView]);

    return (
        <motion.div className='store' ref={ref}
            variants={containerVariants}
            initial="hidden"
            animate={controls}
        >
            <motion.h3 className='store__title' variants={itemVariants}>
                A store that has <br />everything
            </motion.h3>
            <motion.p className='store__subtitle' variants={itemVariants}>
                Manage your store from one place: all you need is already in your admin panel
            </motion.p>
            <motion.div className='store__items'>
                {ITEMS.map((item, i) => (
                    <motion.div className='store__item' key={`store__item_${i}`} variants={itemVariants}>
                        <img className='store__item-img' src={item.img} alt='' />
                        <p className='store__item-title'>{item.title}</p>
                    </motion.div>
                ))}
            </motion.div>
            <motion.button className='store__btn' variants={itemVariants} type='button' onClick={onSignupOpen}>
                Start 14-day trial
            </motion.button>
        </motion.div>
    );
}

export default Store