import { motion } from "framer-motion";
import logo from "../../../assets/images/magnati/logo.webp";
import "./Magnati.css";

const ellipses = Array(4).fill("");

const itemsVariants = {
  hidden: { opacity: 0, scale: 0.3 },
  visible: (i) => ({
    scale: 1,
    opacity: [0, 1, 0],
    transition: {
      duration: 6,
      times: [0, 0.1, 1],
      delay: i * 1.5,
      ease: "easeInOut",
      repeat: Infinity,
    },
  }),
};

function Magnati() {
  return (
    <div className="magnati">
      <p className="magnati__title">Magnati is our trusted&nbsp;partner</p>
      <p className="magnati__subtitle">for your secure online payments</p>
      <div className="magnati__img-block">
        <img className="magnati__logo" src={logo} alt="" />
        <div className="magnati__anim-block">
          {ellipses.map((item, i) => (
            <motion.div
              className="magnati__ellipse"
              key={`magnati-ellipse-${i}`}
              custom={i}
              initial="hidden"
              animate="visible"
              variants={itemsVariants}
            />
          ))}
          <motion.div
            className="magnati__ellipse"
            initial={{ scale: 0.5, opacity: 1 }}
            animate={{ scale: 1, opacity: 0 }}
            transition={{
              duration: 4.5,
              ease: "easeInOut",
            }}
          />
          <motion.div
            className="magnati__ellipse"
            initial={{ scale: 0.8, opacity: 0.4 }}
            animate={{ scale: 1, opacity: 0 }}
            transition={{
              duration: 3,
              ease: "easeInOut",
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Magnati;
