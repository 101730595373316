import { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import CountUp from 'react-countup';

import './Suitable.css';
import { SellaviPointIcon } from '../../../assets/icons/price';
import DonutChart from './DonutChart/DonutChart';
import { LARGE_TYPE, MEDIUM_TYPE, SMALL_TYPE } from '../../../assets/utils/suitable';


const BTNS = [
    SMALL_TYPE,
    MEDIUM_TYPE,
    LARGE_TYPE,
]

const descriptionVariants = {
    initial: { opacity: 0, x: -30 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 30 },
};


function InfoButton({ item, isSelected, onClick }) {
    return (
        <button
            className={`suitable__info-btn ${isSelected ? 'suitable__info-btn_selected' : ''}`}
            type='button'
            onClick={onClick}
        >
            {item.title}
        </button>
    );
}
const whyPointVariants = {
    initial: { opacity: 0, x: -30 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 30 },
};

function WhyPoint({ item, delay }) {
    return (
        <motion.div
            className='suitable__info-why-item'
            variants={whyPointVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={{ duration: 0.5, delay }}
        >
            <SellaviPointIcon
                mainClassName={'suitable__info-why-item-icon'}
            />
            <p className='suitable__info-why-item-text'>{item.title}</p>
        </motion.div>
    );
}


function Suitable() {
    const [selected, setSelected] = useState(SMALL_TYPE)
    const [perviuosSelected, setPerviuosSelected] = useState(SMALL_TYPE)



    return (
        <div className='suitable'>
            <div className='suitable__content'>
                <div className='suitable__info'>
                    <p className='suitable__info-title'>Perfect for any business</p>
                    <div className='suitable__info-btns'>
                        {BTNS.map((item) => (
                            <InfoButton
                                key={`suitable__info-btn-${item.type}`}
                                item={item}
                                isSelected={selected.type === item.type}
                                onClick={() => {
                                    setPerviuosSelected(selected)
                                    setSelected(item)
                                }}
                            />
                        ))}
                    </div>
                    <div className='suitable__chart suitable__chart_mobile'>
                        <DonutChart
                            outerPercentage={selected.main_percent}
                            innerPercentage={selected.clients_percent}
                            prevInnerPercentage={perviuosSelected.clients_percent}
                            type={selected.type}
                        />
                    </div>
                    <p className='suitable__info-main-percent'>
                        <CountUp
                            className='suitable__info-main-percent-numbers'
                            end={selected.main_percent}
                            duration={2.5}
                            enableScrollSpy={true} scrollSpyOnce={true}
                            start={perviuosSelected.main_percent} // You can adjust the starting number for a different effect
                        />
                        %</p>
                    <AnimatePresence mode='wait'>
                        <motion.p
                            key={`suitable__info-main-description_${selected.type}`}
                            variants={descriptionVariants}
                            initial="initial"
                            animate="animate"
                            exit="exit"
                            transition={{ duration: 0.2 }}
                            className='suitable__info-main-description'
                            layout

                        >
                            {selected.main_descriptions}
                        </motion.p>
                    </AnimatePresence>
                    <p className='suitable__info-why-title'>Why?</p>
                    <AnimatePresence mode='wait'>
                        <div className='suitable__info-why-items'>
                            {selected.why_points.map((item, i) => (
                                <WhyPoint
                                    key={`suitable__info-why-item_${selected.type}${i}`}
                                    item={item}
                                    delay={i * 0.1} // Each subsequent item will start animating 0.1 seconds later
                                />
                            ))}

                        </div>
                    </AnimatePresence>
                </div>
                <div className='suitable__chart suitable__chart_pc'>
                    <DonutChart
                        outerPercentage={selected.main_percent}
                        innerPercentage={selected.clients_percent}
                        prevInnerPercentage={perviuosSelected.clients_percent}
                        type={selected.type}
                    />
                </div>

            </div>
        </div>
    );
}

export default Suitable